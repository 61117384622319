import { useEffect } from "react";
import "../Templates/css/style.css";
import Logo from "../Templates/images/logo.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import getUrl from "../../helpers/GetUrl"
function Header({name}) {
  const navigate = useNavigate();
  // const backendurl = process.env.REACT_APP_BASE_URL;
  const backendurl = getUrl()
  const [level, setlevel] = useState(false);
  const [dropdown, setdropdown] = useState(false);
  // const username = localStorage.getItem("user-name");
  function didDropDown() {
    setlevel(localStorage?.getItem("auth-level"));
  }
  const Logout = async() => {
    const response = await axios.delete(`${backendurl}/api/user/management/logout`)
    if (response?.data?.success) {
      navigate("/sign-in")
    } else {
      // console.log(response?.data?.error?.message)
      navigate("/sign-in")
    }
  }
  return (
    <header>
      <div class="dasboard-header">
        <div class="dashboard-item position-relative">
          <div class="header-section d-flex justify-content-between align-items-center">
            <div style={{ cursor: "pointer" }} class="header-logo">
              <img onClick={() => navigate("/")} src={Logo} alt="logo" />
            </div>
            <div class="dropdown-container profile">
              <span class="pro-picture">
                <img
                  src="https://images.unsplash.com/photo-1531746020798-e6953c6e8e04?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1964&q=80"
                  alt=""
                />
              </span>
              <div class="dropdown">
                <button
                  class="btn btn-default dropdown-toggle d-flex align-items-center"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onClick={() => didDropDown()}
                >
                  <span class="d-none d-md-block">{name}</span>
                </button>
                <ul class="dropdown-menu">
                   
                    <li>
                      <p
                        class="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                         // console.log("12345");
                          navigate("/users");
                        }}
                      >
                        Users
                      </p>
                    </li>
                  
                  <li>
                    <p
                      class="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        Logout()
                      }}
                    >
                      Logout
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
