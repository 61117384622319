import "../Templates/css/style.css"
import { ThreeDots } from "react-loader-spinner"
import DataObject from "./Datamanager"
import { useEffect } from "react"
import { useState } from "react"
import ReactPaginate from "react-paginate";
// import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import styles from './Autocomplete.module.css';
import { useNavigate } from "react-router-dom"
function Table({ tableObject, logData, count, limit }) {
        const nav = useNavigate()
        const navigate = (item) => {
                const currentHost = window?.location?.href;
                const parts = item?.redirectionUrl.split("/placements/")
                if (parts?.length == 2) {
                        const url = "/placements/" + parts[1]
                        nav(url, { state: { fromHome: true } })
                }
        }
        const handlePageChange = ({ selected }) => {
                console.log(selected)
                const skip = selected
                onSearchChange(searchvalue, skip)
        };
        const onSearchChange = async (value, skip = 0) => {
                setsearchvalue(value)
                setloading(true)
                const search = value
                if (!search) {
                        const response = await DataObject.getLogData(skip)
                        // console.log(response, response?.length)
                        const refinedData = response?.refinedData;
                        setdata((prev) => [...refinedData])
                        set_count(response.count)
                        set_limit(response?.limit)
                        setloading(false)
                        return;
                }
                const response = await DataObject.getLogSearchData(search, skip)
                // console.log(response, response?.length)
                const refinedData = response?.refinedData;
                setdata((prev) => [...refinedData])
                set_count(response.count)
                set_limit(response?.limit)
                setloading(false)
        }
        // console.log(logData)
        // const bidType = {
        //         0: "Hourly", 1: "Location", 2: "Platform", 3: "Browser", 4: "Language"
        // }
        const [data, setdata] = useState([])
        const [_count, set_count] = useState(5)
        const [_limit, set_limit] = useState(100)
        const [loading, setloading] = useState(false)
        const [searchvalue, setsearchvalue] = useState('')
        useEffect(() => {
                // console.log(logData[0],"mark up 31")
                // eslint-disable-next-line no-empty-pattern
                console.log(count, limit)
                setdata(([]) => [...[], ...logData])
                set_count(count);
                set_limit(limit)
        }, [logData, count, limit])
        // const handleOnSearch = (string, results) => {
        //         // onSearch will have as the first callback parameter
        //         // the string searched and for the second the results.
        //         // console.log(string, results)
        //         onSearchChange(string)
        // }

        // const formatResult = (item) => {
        //         return (
        //                 <>
        //                         <span style={{ display: 'block', textAlign: 'left' }}>{item?.name}</span>
        //                 </>
        //         )
        // }

        return (
                <div class="view-table mt-5">
                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginBottom: "30px", marginTop: "-60px" }}>
                                <div className={styles.autocomplete} style={{ width: 400 }}>
                                        <input
                                                placeholder="Search..."
                                                style={{
                                                        padding: "12px 30px 12px 40px",
                                                        border: "1px solid #C4C4C4",
                                                        borderRadius: "60px",
                                                        fontSize: "16px",
                                                        lineHeight: "18px",
                                                        fontWeight: "400",
                                                        marginRight: "10px",
                                                        color: "#212121",
                                                        width: "100%", boxShadow: "none",
                                                        fontFamily: 'Roboto'
                                                }}
                                                onChange={(e) => onSearchChange(e.target.value)} />
                                </div>
                        </div>
                        {loading && <div id="loader-box"> <span>Loading</span><span style={{ marginTop: "10px" }}>
                                <ThreeDots
                                        height="5"
                                        width="24"
                                        radius="9"
                                        color='black'
                                        ariaLabel='three-dots-loading'
                                        wrapperStyle
                                        wrapperClass />
                        </span> </div>}
                        <table class="table">
                                <thead>
                                        <tr>
                                                {tableObject.time && <th>Time</th>}
                                                {tableObject.user && <th>User</th>}
                                                {tableObject.Pid && <th>Placements</th>}
                                                {tableObject.Publisher && <th> Publisher</th>}
                                                {tableObject.Bid && <th>Bid ID</th>}
                                                {tableObject.type && <th>Bid Type</th>}
                                                {tableObject.oldValue && <th>New value</th>}
                                                {tableObject.newValue && <th>Old Value</th>}

                                        </tr>
                                </thead>
                                <tbody>
                                        {(data?.length > 0) && data?.map((item, index) => {
                                                return (
                                                        <tr style={{ cursor: "pointer" }} onClick={() => navigate(item)} >
                                                                {tableObject.time && <td>{item?.createdAtRefined?.toString().replace("T", "").replace(".000Z", "")}</td>}
                                                                {tableObject.user && <td>{item?.user || "Admin"}</td>}
                                                                {tableObject.Pid && <td style={{ width: "180px", textAlign: "left" }}>{item?.pid} {item?.selectedPlacementName}</td>}
                                                                {tableObject.Publisher && <td style={{ width: "180px", textAlign: "left" }}>{item?.publisherId} {item?.publisher}</td>}
                                                                {tableObject.Bid && <td style={{ width: "180px", textAlign: "center" }}>{item?.bid} </td>}
                                                                {tableObject.type && <td>{item?.type} </td>}
                                                                {tableObject.newValue && <td>{ (item?.fromShutDown == 1) ? 'Shut Down' : item?.boost} </td>}
                                                                {tableObject.oldValue && <td>{(item?.fromShutDown == 2) ? 'Reverse shut Down' : (item?.lastBoost || 0)} </td>}

                                                        </tr>
                                                )
                                        })}
                                </tbody>

                        </table>
                        <ReactPaginate
                                previousLabel={"Previous "}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                pageCount={Math.ceil(_count / _limit)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageChange}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                activeClassName="active-pagination"
                        />
                </div>
        )
}

export default Table;
