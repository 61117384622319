import RestClient from "../../CustomClasses/NetworkManager";

const api = new RestClient(process.env.REACT_APP_BASE_URL);

const endPoint = "api/user/management/set-password/:token"

async function checkUserExists(token) {
        //console.log("Checking for user",endPoint.replace(':token', token))
        try {
                const response = await api.get(endPoint.replace(':token', token))
                return response;
              } catch (error) {
               // console.log(error.message);
                throw error; 
              }
}

const DataObject = {
        checkUserExists
}

export default DataObject;
