import { useNavigate, useLocation } from "react-router-dom";
import newPassLeft from "../Templates/images/new-pass-left.png";
import DataObject from "./DataManager";
import { useState, useEffect } from "react";
import getUrl from "../../helpers/GetUrl";
import axios from "axios";
const NewpasswordComp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const parts = location?.pathname?.split("/");
  let tokenString = parts[parts.length - 1];

  if (location?.state?.token) {
    tokenString = location?.state?.token;
  }
 
  const backendurl = getUrl()
 
  // console.log("location", location?.state?.token);

 
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSetPassword = async () => {
    if (password !== confirmPassword) {
      setPasswordMatchError("Passwords do not match");
      return;
    }
    try {
      const response = await axios.post(
        `${backendurl}/api/user/management/set-password/${tokenString}`,
        {
          passwordhash: password,
        }
      );

      if (response.data.success) {
        navigate("/sign-in");
      } else {
        alert("Setting up password failed");
      }
    } catch (error) {
      console.error("Error setting up password:", error);
    }
  };
  return (
    <section class="login-container mt-4 mt-lg-0">
      <div class="container">
        <div class="content-box">
          <div class="row align-items-xl-center">
            <div class="col-lg-6">
              <div class="left-container">
                <img src={newPassLeft} alt="newPassImage" />
              </div>
            </div>

            <div class="col-lg-6">
              <div class="right-container mx-auto">
                <h1 class="mb-3 mb-md-5">Set your Password</h1>

                <div class="mb-4 pass-sec">
                  <label for="password" class="form-label" id="form-label-one">
                    Password
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    id="password"
                    placeholder="Enter your Password"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <div class="pass-view">
                    <img src="images/pass-eye.svg" alt="" />
                  </div>
                </div>
                <div class="mb-4 pass-sec">
                  <label
                    for="re-enter-pass"
                    class="form-label"
                    id="form-label-two"
                  >
                    Re-Enter Password
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    id="re-enter-pass"
                    placeholder="Re-Enter the password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                  />
                  <div class="pass-view">
                    <img src="images/pass-eye.svg" alt="" />
                  </div>
                  {passwordMatchError && (
                    <div className="text-danger">{passwordMatchError}</div>
                  )}
                </div>
                <div>
                  <button
                    class="btn btn-primary"
                    onClick={() => handleSetPassword()}
                  >
                    Set Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewpasswordComp;
