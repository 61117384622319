import axios from "axios"
import RestClient from "../../CustomClasses/NetworkManager";
import getUrl from "../../helpers/GetUrl"
// const RestClient = require('../../CustomClasses/NetworkManager');
const url = getUrl()
const api = new RestClient(url);
const backendurl = getUrl()
const backendurl_bidding = getUrl()
const backendurl_reports = getUrl()
const endPoint = "api/report/reportData"

const endpointBoostHourly = "api/report/reportData/boostData/:id"

async function getData() {
        try {
                const response = await axios.get(`${backendurl_reports}/api/report/reportData`)
                if (response?.data?.success === false) {
                        return response?.data
                }

                const data = response?.data?.response?.map((item, i) => {
                       
                        return { name: item?.name, value: String(item?.Rohertrag), winRate: String(item["Win-Rate"]),
                         id: item?.id, color: item?.color, lightColor: item?.lightColor,
                          didShutDown: item?.didShutDown }
                })
               
                const refinedData = { list: data, level: response?.data?.level || 1 }

                return refinedData;
        } catch (error) {

                if (error?.response?.data?.message === "KMD_NOT_FOUND") {
                        return error
                }
                alert("Cound not load data from server")

        }
}
async function getDataWS(id) {
        try {
                const response = await axios.get(`${backendurl_reports}/api/report/reportData/wsid/${id}`)
                if (response?.data?.success === false) {
                        return response?.data
                }
                const data = response?.data?.response?.map((item, i) => {
                        return { name: item?.name, value: String(item?.Rohertrag), winRate: String(item["Win-Rate"]), id: item?.id, color: item?.color, lightColor: item?.lightColor }
                })
                const refinedData = { list: data, level: response?.data?.level || 1 }

                return refinedData;
        } catch (error) {
                return error
        }
}
async function getDataPlacements(id) {
        try {
                const response = await axios.get(`${backendurl_reports}/api/report/reportData/pid/${id}`)
                if (response?.data?.success === false) {
                        return response?.data
                }
                const data = response?.data?.response?.map((item, i) => {
                        return { name: item?.name, value: String(item?.Rohertrag), winRate: String(item["Win-Rate"]), id: item?.id, color: item?.color, lightColor: item?.lightColor }
                })
                const refinedData = { list: data, level: response?.data?.level || 1 }

                return refinedData;
        } catch (error) {
                return error
        }
}
async function getDataForCakeChart() {
        const response = await api.get(endPoint)
        let data = response?.data?.data;
        data = data.filter(function (item, index) {
                if (item.grossProfit > 0) {
                        return true
                } else {
                        return false
                }
        })
        data = data.map((item, i) => {
                return { name: item?.name, value: String(item?.Rohertrag)?.slice(0, 3) }
        })
        return data;
}

async function Postboosts(body) {

        const response = await axios.post(`${backendurl_bidding}/api/bidding/setBoost`, body)

        let data = response?.data?.success;
        return data;
}



async function getTableAndChartData(wsid, pid, day = "today") {
        const endPointWithParams = `${backendurl_reports}/api/report/reportData/pid/:id?wsid=${wsid}&pid=${pid}&day=${day}`;


        try {
                const response = await axios.get(`${backendurl_reports}/api/report/reportData/placementData?wsid=${wsid}&pid=${pid}&day=${day}`)
                return response?.data;
        } catch (error) {
                return error
        }
}

async function getBidLogByPID(pid){
        try{
                let response = await axios.get(`${backendurl}/api/bidding/bid-log/${pid}`)
                if(response?.data?.success){
                        return response.data.data
                }
        } catch (error) {
                return error
        }
}

async function getLogData(skip = 0) {
        try {
                let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: `${backendurl_bidding}/api/bidding/list-log?skip=${skip}`,
                        headers: {}
                };
                const response = await axios.get(`${backendurl_bidding}/api/bidding/list-log?skip=${skip}`);

                if (response?.data?.success) {
                        const refinedData = response?.data?.data?.map((item, index) => {
                                const timeSt = item?.createdAt;
                                var output = [timeSt.slice(0, 10), "  ", timeSt.slice(10)].join('');
                                return { ...item, ...{ createdAtRefined: output } }
                        })
                        const count = response?.data?.count
                        const limit = response?.data?.limit
                        return { refinedData, count, limit };
                } else {
                        return false
                }
        } catch (error) {
                return error
        }
}
async function getShutDownLogData(id) {
        let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${backendurl_bidding}/api/bidding/get-log-status`,
                headers: {}
        };
        const response = await axios.get(`${backendurl_bidding}/api/bidding/get-log-status/${id}`);

        if (response?.data?.success) {
                return response?.data?.data
        } else {
                return false
        }
}

async function getLogSearchData(keyword, skip) {

        const response = await axios.get(`${backendurl_bidding}/api/bidding/search-log?search=${keyword}&skip=${skip}`);

        if (response?.data?.success) {
                const refinedData = response?.data?.data?.map((item, index) => {
                        const timeSt = item?.createdAt;
                        var output = [timeSt.slice(0, 10), "  ", timeSt.slice(10)].join('');
                        return { ...item, ...{ createdAtRefined: output } }
                })
                const count = response?.data?.count
                const limit = response?.data?.limit
                return { refinedData, count, limit };
        } else {
                return false
        }
}

async function getBoostHourlyData(id) {
        try {
                const response = await axios.get(`${backendurl_reports}/api/report/reportData/boostData/${id}`)
                return response;
        } catch (error) {
                return error
        }
}

async function getEntityInfo() {
        const response = await axios.get(`${backendurl_reports}/api/report/entity-info`);

        const data = response?.data?.data?.map((item, index) => {
                return {
                        id: (index + 1), label: String(item?.name).replace(/[^a-zA-Z\u00C0-\u017F ]/g, "")
                        , parentID: item?.parentID, e_id: item?.entityID, type: item?.type, parentName: item?.parentName || ""
                }
        })
        return data
}

const DataObject = {
        getData, getDataForCakeChart, Postboosts, getDataWS, getLogSearchData, getShutDownLogData,
        getTableAndChartData, getDataPlacements, getLogData, getBoostHourlyData, getEntityInfo, getBidLogByPID
}

export default DataObject;
