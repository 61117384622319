import React, { useState } from "react";
import styles from "./Autocomplete.module.css";
import { useNavigate } from "react-router-dom";

function Autocomplete({
  options,
  fromHome,
  fromWebSite,
  fromUsers,
  onSelect,
  setPublisherId,
  setPublisherName,
  disabled,setEmail
}) {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);
    const filtered = options.filter((option) =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredOptions(disabled === true ? [] : ([]) => [...[], ...filtered]);
  };

  const itemSelected = (item) => {
    if (fromUsers) {
     
      setInputValue(item.label);
      setPublisherId(item.id);
      setPublisherName(item?.title);
      setEmail(item?.email)
      setFilteredOptions(() => []);
      return;
    }

    if (onSelect) {
      onSelect(item);
    }
    console.log(item)
    if (item.type === "publisher") {
      navigate(`/publisher/${item?.label}/${item?.e_id}`.replace(" ", ""));
    } else {
      navigate(
        `/placements/${item?.label}/${item?.e_id}/${item?.parentName}/${
          item.parentID
        }`.replace(" ", ""),{state:{fromHome:fromHome,fromWebSite:fromWebSite}}
      );
    }
  };

  return (
    <div className={styles.autocomplete}>
      <input
        style={{
          padding: "12px 30px 12px 40px",
          border: "1px solid #C4C4C4",
          borderRadius: "60px",
          fontSize: "16px",
          lineHeight: "18px",
          fontWeight: "400",
          color: "#212121",
          width: "100%",
          boxShadow: "none",
          fontFamily: "Roboto",
          textAlign: "center"
        }}
        type="text"
        placeholder="Search..."
        value={inputValue}
        onChange={handleInputChange}
        disabled={disabled}
      />
      {(inputValue.length > 1) &&  (filteredOptions?.length > 0) && (  <ul
          className={styles.resultList}
          style={{
            padding: "12px 30px 12px 40px",
            border: "1px solid #C4C4C4",
            borderRadius: "8px",
            fontSize: "16px",
            lineHeight: "18px",
            fontWeight: "400",
            color: "#212121",
            boxShadow: "none",
            fontFamily: "Roboto",
          }}
        >
          {filteredOptions.map((option) => (
            <li style={{cursor:"pointer"}} onClick={() => itemSelected(option)} key={option.id}>
              <span style={{marginTop:"4px",height:"30px"}}>{option.label}</span>
              <hr />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Autocomplete;
