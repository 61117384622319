import PriceWrapper from "./PriceWrapper";
import { useNavigate } from "react-router-dom";
import "./WebSiteCard.css";
export default function ({
  item,
  publisherName,
  websiteMode,
  onClick,
  isSelected,
  publisherId,
  level,
  color,
  lightColor,
}) {
  const handleCardClick = () => {
    // Call the onClick callback provided by the parent
    onClick();
  };
  const navigate = useNavigate();
  const nextPage = () => {
   // console.log("athira navigate");
    // console.log("level==>", level);
    // if (level > 2 || level < 0) {
      navigate(
        `/placements/${item?.title}/${item?.id}/${publisherName}/${publisherId}`.replace(
          " ",
          ""
        )
      );
    // }
  };

  return (
    <div class="card-col">
      {websiteMode ? (
        <a
          onClick={() => nextPage()}
          class="info-card d-block"
          style={{ cursor: "pointer" }}
        >
          <div class="ssp-holder d-flex align-items-center justify-content-between mb-2">
          <span class="ssp-name">{item?.id}</span>
          <br />
            <span class="ssp-name">{item?.title}</span>
          </div>

          <div class="status-holder d-flex align-items-center justify-content-evenly">
            <div
              class="link-wrap d-flex justify-content-center align-items-center"
              style={{ backgroundColor: color }}
            >
              <span>
                <i class="bi bi-link-45deg" style={{ color: "white" }}></i>
              </span>
            </div>
            <div class="income-wrap d-flex flex-column">
              <span class="money-value mb-2">
                <PriceWrapper value={item.value} />
              </span>
              <span class="caption-text">Today’s Earnings</span>
            </div>
            <div class="value-wrap decrement d-flex align-items-center">
              <span></span>
              <span class="value-text"></span>
            </div>
          </div>
        </a>
      ) : (
        <div
          className={`placement-card info-card d-block ${
            isSelected && "selected-card"
          }`}
          onClick={handleCardClick}
          style={{ cursor: "pointer" }}
        >
          <div class="ssp-holder d-flex align-items-center justify-content-between mb-2">
          <span class="ssp-name">{item?.id}</span>
          <br />
            <span class="ssp-name">{item?.title}</span>
          </div>

          <div class="status-holder d-flex align-items-center justify-content-evenly">
            <div
              class="link-wrap d-flex justify-content-center align-items-center"
              style={{ backgroundColor: color }}
            >
              <span>
                <i class="bi bi-link-45deg" style={{ color: "white" }}></i>
              </span>
            </div>
            <div class="income-wrap d-flex flex-column">
              <span class="money-value mb-2">
                <PriceWrapper value={item.value} />
              </span>
              <span class="caption-text">Today’s Earnings</span>
            </div>
            <div class="value-wrap decrement d-flex align-items-center">
              <span></span>
              <span class="value-text"></span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
