import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {GoogleOAuthProvider} from "@react-oauth/google"

const root = ReactDOM.createRoot(document.getElementById('root'));
const GID = String(process.env.REACT_APP_GOOGLE_AUTH_ID)
root.render(
  // <React.StrictMode>
      <GoogleOAuthProvider
                clientId={GID}>
       <App />
       </GoogleOAuthProvider>
  // </React.StrictMode>
);

