import axios from "axios";

export const addData = async (body, url) => {
  try {
    const response = await axios.post(url, body);
    if (response.status === 201) {
      return {
        success: true,
      };
    } else {
      return {
        success: false,
        data: response?.data,
      };
    }
  } catch (error) {
    // console.log("Something went wrong", error);
    return {
      success: false,
      message: "unknown issue",
    };
  }
};

export const editData = async (body, url) => {
  try {
    const headers = {
      'Content-Type': 'application/json', // Specify the content type if needed
      'Authorization': localStorage?.getItem('auth-token'), // Include any authorization headers if required
      // Add any other headers as needed
    };
    // console.log("32 data adder",localStorage?.getItem('auth-token'))
    const response = await axios.put(url, body, {  headers });
      if (response?.data?.success) {
        return {
          success: true,
          data: response?.data?.message,
        };
      } else {
        return {
          success: false,
          data: response?.data,
        };
      }
  } catch (error) {
    // console.log("Something went wrong", error);
    return {
      success: false,
      data: {message:"unknown issue"},
    };
  }
};
