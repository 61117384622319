import { useState, useEffect } from "react";
import axios from 'axios'

const useAuthorize = (btnClicked) => {
    const [signedin, setSignedIn] = useState(false);
    const verify = async () => {
        // get token from localstorage
        const token = localStorage.getItem('auth-token');
        if (!token){
            return
        } else {
            setSignedIn(true)
            return
        }
        // verify the token is not malicious and has not expired
        const url = process.env.REACT_APP_BASE_URL + '/user/verify-token'
        const headers =  {
            Authorization: token
        } 
      //  console.log(headers)
        const response = await axios.post(url, {}, { headers: headers}).catch(err => console.log(err.message))
        if (response?.data?.success) {
            setSignedIn(true)
        } else {
            setSignedIn(false)
        }
    }
    useEffect(  ()=>  {
         verify()
    }, []);
    return true;
};

export default useAuthorize;