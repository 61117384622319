import "../Templates/css/style.css";
import barChartOne from "../Templates/images/bar-chart-icon.svg";
// import { addData, editData } from "../../services/DataAdder";
import { addData } from "../../services/DataAdder";
import { editData } from "../../services/DataAdder";
import { useEffect, useState } from "react";
import { useLocation, useNavigate} from "react-router-dom";
import getUrl from "../../helpers/GetUrl";
import axios from "axios";
// require("dotenv").config();

const EditUser = ({ isVerified }) => {
  const navigator = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const location = useLocation();
  const [username, setUsername] = useState(location.state.username);
  const [email, setEmail] = useState(location.state.email);
  const [isPublisher, setIsPublisher] = useState(location.state.isPublisher);
  const [publisherID, setPublisherID] = useState(location.state.publisherID);
  const [permissionLevel, setPermissionLevel] = useState(
    location.state.permissionLevel
  );
  const [userID, setUserID] = useState("");
  const [expiry, setexpiry] = useState("");
  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);

    if (newPassword.length < 6) {
      setPasswordError("Password must be at least 6 characters long");
    } else {
      setPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (event) => {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);

    if (newConfirmPassword !== password) {
      setConfirmPasswordError("Passwords do not match");
      return;
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleIsPublisherChange = (e) => {
    setIsPublisher(e.target.checked);
  };

  const handlePublisherIDChange = (e) => {
    setPublisherID(e.target.value);
  };

  const handlePermissionLevelChange = (e) => {
    setPermissionLevel(e.target.value);
  };
  useEffect(() => {
    setUsername(location.state.username);
    setEmail(location.state.email);
    setPublisherID(location.state.publisherID);
    setPermissionLevel(location.state.permissionLevel);
    setUserID(location.state.id);
    // console.log("expiry =>", location.state.expiry)
    if (location?.state?.expiry !== "") {
      setexpiry(location?.state?.expiry);
    }
  }, []);

  function generateRandomToken() {
    const charset =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let token = "";
    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      token += charset.charAt(randomIndex);
    }
    return token;
  }

  // update kmd

  const updateKMD = async () => {
    const url =
      getUrl() +
      "/api/user/management/update-kmd/userID".replace("userID", userID);
    const response = await axios.put(url);
    if (response?.data?.success) {
      setexpiry("");
      alert("updated");
    } else {
      alert("failed");
    }
  };

  // Update a user
  const editUser = async (isVerified) => {
    if (userID === undefined) {
      console.error("userID is undefined");
      return;
    }
    const id = userID;
    const username = document.getElementById("username")?.value;
    const email = document.getElementById("email")?.value;
    const publisherID = document.getElementById("publisher-id")?.value;

    const url = getUrl() + "/api/user/management/user-update";
    let data;
    if (isVerified) {
      data = {
        id,
        username,
        email,
        permissionLevel,
        isPublisher: selectedOption === "Publisher",
        publisherID: publisherID,
        verified: true,
        tokenString: generateRandomToken(),
      };
    } else {
      data = {
        id,
        verified: true,
        permissionLevel,
      };
    }

    if (selectedOption === "Publisher") {
      data.publisherID = publisherID;
    }

    if (selectedOption === "External User") {
      data.permissionLevel = permissionLevel;
    }

    const response = await editData(data, url);
    if (response.success === true && isVerified === true) {
      window.alert("User updated successfully!");
    } else if (response.success === true && isVerified === false) {
      window.alert("User verified successfully!");
    } else {
      if (response?.data?.isSignInRequired) {
        navigator("/sign-in");
        return;
      }
      if (response?.data?.forbidden) {
        alert("forbidden");
        return;
      }
      console.error("User updation failed:", response.message);
    }
  };
  const handleBack = () => {
    navigator("/users");
  };
  return (
    <section>
      <div class="dashboard-container">
        <div class="dashboard-heading d-flex align-items-center">
          <span class="me-2">
            <img src={barChartOne} alt="image" />
          </span>
          <span class="heading-text">
            {location?.state?.verified ? "Edit user" : "Verify"}
          </span>
        </div>
        <div class="add-user-container">
          <div class="row">
            <div class="col-md-6 col-lg-4 col-xl-3">
              <div class="mb-4">
                <label for="name" class="form-label">
                  Username
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="username"
                  placeholder="Enter your Name"
                  value={username}
                  disabled={true}
                  onChange={handleUsernameChange}
                />
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3">
              <div class="mb-4">
                <label for="mail" class="form-label">
                  E-mail ID
                </label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  placeholder="Enter your mail"
                  value={email}
                  disabled={true}
                  onChange={handleEmailChange}
                />
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3">
              <div class="mb-4">
                <label for="category-type" class="form-label">
                  Category Type
                </label>
                <div class="d-flex justify-content-between">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      //   checked={selectedOption === "Publisher"}
                      checked={location.state.isPublisher}
                      id="category-type"
                      value="Publisher"
                      onChange={handleOptionChange}
                    />
                    <label class="form-check-label" for="c-type">
                      Publisher
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      //   checked={selectedOption === "External User"}
                      //   checked={location.state.isPublisher === false}
                      checked={!location.state.isPublisher}
                      id="c-type"
                      value="External User"
                      onChange={handleOptionChange}
                    />
                    <label class="form-check-label" for="c-type">
                      Publisher-sub-account
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3">
              <div class="mb-4">
                <label for="publisher-id" class="form-label">
                  Publisher ID
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="publisher-id"
                  placeholder="Eg: ID 123456"
                  disabled={true}
                  value={publisherID}
                  onChange={handlePublisherIDChange}
                />
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3">
              <div class="mb-4">
                <label for="external-user" class="form-label">
                  Permission Level
                </label>
                <select
                  class="form-select form-control"
                  aria-label="Default select example"
                  id="permission-level"
                  //   disabled={selectedOption === "Publisher"}
                  disabled={location.state.isPublisher === true}
                  value={permissionLevel}
                  onChange={handlePermissionLevelChange}
                >
                  <option value="1">1 [Publisher view]</option>
                  <option value="2">2 [Level 1 + Website]</option>
                  <option value="3">3 [Level 2 + Placement]</option>
                  <option value="4">4[Level 3 + Graph view] </option>
                  <option value="5">5 [Level 4 + Update boost]</option>
                  <option value="-2"> -2 [Publisher data access]</option>
                  {email.endsWith("twiago.com") && (
                    <option value="-1"> -1 [Super admin + user add]</option>
                  )}
                </select>
              </div>
            </div>
            {expiry !== "" && (
              <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="mb-4">
                  <label for="external-user" class="form-label">
                    KMD Token expiry
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="publisher-id"
                    placeholder="Eg: ID 123456"
                    disabled={selectedOption === "External User"}
                    value={expiry}
                    onChange={handlePublisherIDChange}
                  />
                </div>
              </div>
            )}
            <div class="d-flex justify-content-center align-items-center mt-3 mt-md-4">
              <button
                class="save-btn"
                // onSubmit={handleSubmit}
                onClick={() => editUser(location.state.verified)}
              >
                {location.state.verified ? "Update User" : "Verify"}
              </button>
              {expiry !== "" && (
                <button
                  class="save-btn"
                  // onSubmit={handleSubmit}
                  onClick={() => updateKMD()}
                >
                  Update KMD
                </button>
              )}
              <button className="back-button" onClick={handleBack}>
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default EditUser;
