import "../Templates/css/style.css";
import barChartOne from "../Templates/images/bar-chart-icon.svg";
import { addData } from "../../services/DataAdder";
import { useState, useEffect } from "react";
import axios from "axios";
import Autocomplete from "../Dashboard/AutoComplete";
import { useNavigate } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import getUrl from "../../helpers/GetUrl";

// require("dotenv").config();

const AddUser = () => {
  const navigator = useNavigate();
  const backendurl_reports = getUrl();
  const [selectedOption, setSelectedOption] = useState("Publisher");
  const [publisherId, setPublisherId] = useState("");
  const [publisherName, setPublisherName] = useState("");
  const [password, setPassword] = useState("");
  const [forbidden, setforbidden] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [searchparams, setsearchparams] = useState("1");
  const [loading, setloading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [publisherData, setPublisherData] = useState([]);
  const [permissionLevel, setPermissionLevel] = useState("1");
  const [formData, setFormData] = useState([])
  const [formDataId, setFormDataId] = useState([])
  const backendurl = process.env.REACT_APP_BASE_URL;
  const handlePermissionLevelChange = (e) => {
    setPermissionLevel(e.target.value);
  };
  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);

    if (newPassword.length < 6) {
      setPasswordError("Password must be at least 6 characters long");
    } else {
      setPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (event) => {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);

    if (newConfirmPassword !== password) {
      setConfirmPasswordError("Passwords do not match");
      return;
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  function generateRandomToken() {
    const charset =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let token = "";
    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      token += charset.charAt(randomIndex);
    }
    return token;
  }

  function isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    return emailRegex.test(email);
  }

  const newUser = async () => {
    // console.log("Adding new user");
    // console.log("BaseURL", process.env.REACT_APP_BASE_URL);
    //const username = document.getElementById("username")?.value;
    //const publisherUserName = document.getElementById("publisher-id")?.value;
    //const email = document.getElementById("email")?.value;

    const isEmailStrValid = isValidEmail(email);
    if (!isEmailStrValid) {
      alert("Email looks like improper format");
      return;
    }

    // const publisherName = publisher_numID;
    // const publisherName = selectedOption?.label;
    // const publisherName = document.getElementById("publisher-id")?.value;
    // const publisherName = publisher;
    // let publisherName = "";
    // console.log("publisherID==>", publisherID);
    // if (publisherID) {
    //   console.log("publisherID==>", publisherID);
    //   publisherName = publisherID;
    // }

    const permissionLevel = parseInt(
      document.getElementById("permission-level")?.value,
      10
    );
    const url = getUrl() + "/api/user/management/sign-up";

    let data = {
      username,
      email,
      isPublisher: selectedOption === "Publisher",
      verified: false,
      tokenString: generateRandomToken(),
      permissionLevel,
    };

    if (selectedOption === "Publisher") {
      if (publisherId?.length < 3) {
        alert("Looks like publisher is no selected");
        return;
      }
      delete data.username;
      data = {
        ...data,
        ...{
          publisherID: publisherId,
          publisherName: publisherName,
          username: publisherName,
        },
      };
    } else {
      if (username?.length < 3) {
        alert("Looks like username is not added");
        return;
      }
    }

    setloading(true);
    try {
      const response = await addData(data, url);
      setloading(false);
      if (response.success === true) {
        window.alert("User added successfully!");
      } else {
        if (response?.data?.isSignInRequired) {
          navigator("/sign-in");
          return;
        }
        if (response?.data?.forbidden) {
          alert("forbidden");
          return;
        } else {
          alert(response?.data?.message);
        }
        console.error("User addition failed:", response?.data?.message);
      }
    } catch (error) {
      if (error?.response?.data?.showForbidden) {
        setforbidden(true)
      }
    }

  };

  useEffect(() => {
    const fetchPublisherData = async () => {
      try {
        // console.log("line 11");
        // console.log("marker 155")
        const response = await axios.get(
          `${backendurl_reports}/api/report/publisherData?apicall=1&apitype=json&apiget=ids&apigetfields=strEmail,strFirma&kmd=8143af9411c2e397498fd2435d01a18695d9ce0c246e71f23b03e2353d8d0ff0`);
        // console.log("Response:", response.data)
        // setPublisherData(response.data.intID);
        const formattedData = response?.data?.value?.map((item) => ({
          id: item.intID, // Adjust the property names as needed
          label: item.strFirma,
          email: item.strMail,
          title: item.strFirma,
          // ... other properties you need
        }));
        setFormData(() => formattedData)
        const formattedDataId = response?.data?.value?.map((item) => ({
          id: item.intID, // Adjust the property names as needed
          label: item.intID,
          email: item.strMail,
          title: item.strFirma,
          // ... other properties you need
        }));
        setFormDataId(() => formattedDataId)
        setPublisherData(formattedData);

      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    // console.log("marker 179")
    fetchPublisherData();
  }, []);

  const handleBack = () => {
    navigator("/users");
  };

  if (forbidden) {
    return <h3>Forbidden</h3>
  }
  return (
    <section>
      <div class="dashboard-container">
        <div class="dashboard-heading d-flex align-items-center">
          <span class="me-2">
            <img src={barChartOne} alt="image" />
          </span>
          <span class="heading-text">Add New</span>
        </div>
        <div class="add-user-container">
          <div class="row">
            <div class="col-md-6 col-lg-4 col-xl-3">
              <div class="mb-4">
                <label for="name" class="form-label">
                  Username
                </label>
                <input
                  type="text"
                  class="form-control"
                  disabled={selectedOption === "Publisher"}
                  value={
                    selectedOption === "Publisher" ? publisherName : username
                  }
                  id="username"
                  onChange={(e) => setUsername(e?.target?.value)}
                  placeholder="Enter your Name"
                />
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3">
              <div class="mb-4">
                <label for="mail" class="form-label">
                  E-mail ID
                </label>
                <input
                  type="email"
                  class="form-control"
                  value={email}
                  onChange={(e) => setEmail(e?.target?.value)}
                  id="email"
                  placeholder="Enter your mail"
                />
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3">
              <div class="mb-4">
                <label for="category-type" class="form-label">
                  Category Type
                </label>
                <div class="d-flex justify-content-between">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      checked={selectedOption === "Publisher"}
                      id="category-type"
                      value="Publisher"
                      onChange={handleOptionChange}
                    />
                    <label class="form-check-label" for="c-type">
                      Publisher
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      checked={selectedOption === "External User"}
                      id="c-type"
                      value="External User"
                      onChange={handleOptionChange}
                    />
                    <label class="form-check-label" for="c-type">
                      Publisher-sub-account
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3">
              <div class="mb-4">
                <label for="publisher-id" class="form-label">
                  Publisher
                </label>

                <div style={{ margin: "10px", paddingBottom: "10px" }} for="publisher-id" class="form-label">
                  <select

                    defaultValue={searchparams}
                    onChange={(e) => {
                      if (e.target.value === "1") {
                        // console.log(formData)
                        setPublisherData(() => formData)
                      } else {
                        // console.log(formDataId)
                        setPublisherData(() => formDataId)
                      }
                      setsearchparams(e.target.value)
                    }}
                    style={{ height: "30px", width: "100%", paddingLeft: "10px", backgroundColor: "transparent", borderWidth: "0px", borderBottomWidth: "2px" }}


                  >
                    <option value="1">Search by name</option>
                    <option value="2">Search by ID</option>


                  </select>
                </div>
                <Autocomplete
                  options={publisherData}
                  setPublisherId={setPublisherId}
                  setPublisherName={setPublisherName}
                  setEmail={setEmail}
                  id="publisher-id"
                  fromUsers={true} // Pass the publisherData array as options
                  onSelect={(selectedOption) => {
                    setPublisherId(selectedOption.label);
                  }}
                  disabled={selectedOption !== "Publisher"}
                />
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3">
              <div class="mb-4">
                <label for="external-user" class="form-label">
                  Permission Level
                </label>
                <select
                  class="form-select form-control"
                  aria-label="Default select example"
                  id="permission-level"
                  value={permissionLevel}
                  onChange={handlePermissionLevelChange}
                >
                  <option value="1">1 [Publisher view]</option>
                  <option value="2">2 [Level 1 + Website]</option>
                  <option value="3">3 [Level 2 + Placement]</option>
                  <option value="4">4 [Level 3 + Graph view]</option>
                  <option value="5">5 [Level 4 + Update boost]</option>
                  {selectedOption === "Publisher" && (
                    <option value="-2" selected>
                      {" "}
                      -2 [Publisher data access]
                    </option>
                  )}
                  {/* {email.endsWith("twiago.com") && (
                    <option value="-1">
                      {" "}
                      -1 [Super admin + user add]
                    </option>
                  )} */}
                </select>
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-3 mt-md-4">
              <button
                class="save-btn"
                // onSubmit={handleSubmit}
                onClick={() => newUser()}
              >
                Create User
                {loading && (
                  <ColorRing
                    colors={[
                      "#ffe9f0",
                      "#f4e4a4",
                      "#ffe9f0",
                      "#f8e6ff",
                      "#ffe9f0",
                    ]}
                    height="25"
                    width="25"
                    color="#4fa94d"
                    ariaLabel="Signing in"
                    wrapperStyle={{}}
                    wrapperClass="wrapper-class"
                    visible={true}
                  />
                )}
              </button>
              <button className="back-button" onClick={handleBack}>
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AddUser;
