import "../Templates/css/style.css";
import BarChartIcon from "../Templates/images/bar-chart-icon.svg";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import getUrl from "../../helpers/GetUrl";
import ReactPaginate from "react-paginate";
const Users = () => {
  const [userData, setUserData] = useState([]);
  const navigate = useNavigate();
  const backendurl = getUrl();
  const [currentPage, setCurrentPage] = useState(0); // Initial page
  const [pageData, setPageData] = useState(0);
  const [forbidden, setforbidden] = useState(false);
  const [totalItem, setTotalItem] = useState(0);
  const itemsInPage = 20; // Number of items to display per page

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${backendurl}/api/user/management/users-list?limit=${itemsInPage}&offset=${currentPage}`);
       // console.log(response?.data)
        if (response?.data?.isSignInRequired) {
          navigate("/sign-in");
          return;
        }
        setUserData(response.data.usersData);
        setPageData(response.data.itemsPerPage);
        setTotalItem(response.data.totalItems);
      } catch (error) {
        console.error("Error fetching user data:", error);
        if (!error?.response?.data.success && error?.response?.data?.showForbidden) {
          return setforbidden(true)
        }
      }
    };

    fetchUserData();
  }, [currentPage]);

  const handleRowClick = (user, verified) => {
      let dateString = ""
      if (user?.expiry && !user?.email?.toString().includes("@twiago.com")) {
        const timeOffset = new Date().getTime() + ( 1000 * 60 * 60 * 24 * 7 * 8 )
        if (user?.expiry < timeOffset) {
          const date = new Date(user?.expiry)
          dateString = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}  ${date.getHours().toString().padStart(2, '0')}-${date.getMinutes().toString().padStart(2, '0')}-${date.getSeconds().toString().padStart(2, '0')}`
        }
      }
    navigate(`/edit-user/${user.id}`, {
      state: {
        id: user.id,
        username: user.username,
        isPublisher: user.isPublisher,
        publisherID: user.publisherID,
        permissionLevel: user.permissionLevel,
        email: user.email,
        verified: verified,
        expiry:dateString
      },
    });
  };
  const totalItems = totalItem; // Your total number of items in the list;
  const itemsPerPage = pageData;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  // Calculate the start and end index of the items to display for the current page
  // const startIndex = currentPage * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  // const currentItems = userData.slice(startIndex, endIndex);

  // Handle page change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  if (forbidden) {
    return <h3>Forbidden</h3>
  }
  return (
    <section>
      <div class="dashboard-container">
        <div class="advertisment-info-table">
          <div class="d-flex justify-content-between align-items-center flex-wrap">
            <div class="dashboard-heading d-flex align-items-center mb-0">
              <span class="me-2">
                <img src={BarChartIcon} alt="image" />
              </span>
              <span class="heading-text">User Details List</span>
            </div>
            <div class="add-user-btn-wrap mt-4 mt-md-0">
              <p
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/add-user")}
                class="add-btn"
              >
                Add User
              </p>
            </div>
          </div>
          <div class="advertisment-table-container mt-4 mt-md-5">
            <div class="view-table user-table">
              <table class="table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                    <th>Mail </th>
                    <th>Type</th>
                    <th>Publisher ID</th>
                    <th>Permission Level</th>
                    <th>Verification</th>
                  </tr>
                </thead>
                <tbody>
                  {userData?.map((user, index) => (
                    <tr
                      key={index}
                      onClick={() => {
                        if (user?.verified) {
                          handleRowClick(user, user?.verified);
                        }
                      }}
                      // class={user.verified ? "" : "light-green-row"}
                      style={{
                        backgroundColor: user.verified ? "#fff" : "#bbb",
                        cursor: user?.verified ? "pointer" : "auto",
                      }}
                    >
                      <td style={{ backgroundColor: "transparent" }}>
                        { (currentPage * itemsInPage) + (index + 1)}
                      </td>
                      <td style={{ backgroundColor: "transparent" }}>
                        {/* <a href="/add-user">{user.username}</a> */}
                        {user.username}
                      </td>
                      <td style={{ backgroundColor: "transparent" }}>
                        {user.email}
                      </td>
                      <td style={{ backgroundColor: "transparent" }}>
                        {user.isPublisher ? "Publisher" : "External User"}
                      </td>
                      <td style={{ backgroundColor: "transparent" }}>
                        {user.publisherID}
                      </td>
                      <td style={{ backgroundColor: "transparent" }}>
                        {user.permissionLevel}
                      </td>
                      {user?.verified && (
                        <td
                          style={{
                            backgroundColor: "transparent",
                            cursor: user.verified ? "auto" : "pointer",
                          }}
                          onClick={() => handleRowClick(user, user?.verified)}
                        >
                          verified
                        </td>
                      )}
                      {!user?.verified && (
                        <td
                          style={{
                            backgroundColor: "transparent",
                            cursor: user.verified ? "auto" : "pointer",
                          }}
                          onClick={() => handleRowClick(user, user?.verified)}
                        >
                          <span style={{ textDecoration: "underline" }}>
                            verify
                          </span>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* Render pagination component */}
              <ReactPaginate
                previousLabel={"Previous "}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                activeClassName="active-pagination"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Users;
