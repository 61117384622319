import axios from "axios";

// const addData = async (body, url) => {
//     try {
//         const response = await axios.post(url,body)
//         if (response.status === 201) {
//             return {
//                 success:true
//             }
//         } else {
//             return {
//                 success:false,
//                 message:response?.data?.message
//             }
//         }
//     } catch (error) {
//         return {
//             success:false,
//             message:"unknown issue"
//         }
//     }
// }

const normalLoginApi = async(url, body) => {
    // console.log('Normal login API called. URL: ',url,'Body: ',body)
    try {
        const response = await axios.post(url,body)
       
        return response?.data
    } catch (error) {
        return {
            success:false,
            message:"unknown issue"
        }
    }
}

export default normalLoginApi;