class RestClient {
  constructor(baseURL) {
    this.baseURL = baseURL;
  }

  async get(endpoint) {
    try {
      const response = await fetch(`${this.baseURL}/${endpoint}`,{
        methodL:"GET",
        credentials: 'include',
      },
       {
        "Content-Type": "application/json",
        headers: this.headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      throw new Error(`Error while making GET request: ${error.message}`);
    }
  }

  async post(endpoint, data) {
    try {
      // console.log("jkjuhkiuhuhoiuh");
      const response = await fetch(`${this.baseURL}/${endpoint}`, {
        method: "POST", credentials: 'include',
        headers: {
          "Content-Type": "application/json",
            headers: this.headers,
        },
        body: JSON.stringify(data),
      });
     // console.log("res==>", response);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return await response.json();
    } catch (error) {
     //  console.log(error.message)
      // throw new Error(`Error while making POST request: ${error.message}`);
    }
  }
}

export default RestClient
