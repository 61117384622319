import barChart from "../Templates/images/bar-chart-icon.svg";
import "./Custom.css";
import ProgressBar from "@ramonak/react-progress-bar";
import "../Templates/css/style.css";
import PieChart from "./PieChartComponent";
import Table from "./Table";
import SSPCard from "./SSPCard";
import { useEffect, useState } from "react";
import DataObject from "./Datamanager";
import { ColorRing } from "react-loader-spinner";
import Autocomplete from "./AutoComplete";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigator = useNavigate();
  const [noData, setNoData] = useState(false);
  const [noLogData, setNoLogData] = useState(false);
  const [slider, setSlider] = useState(true);
  const level = localStorage?.getItem("auth-level") || 1;
  const [data, setdata] = useState([]);
  const [cakeData, setCakeData] = useState([]);
  const [auctionData, setAuctionData] = useState([]);
  const [logData, setLogData] = useState([]);
  const [count, setcount] = useState(5)
  const [limit, setlimit] = useState(100)
  const [entity, setEntity] = useState([]);
  const [shutdownlogMsg, setshutdownlogMsg] = useState("");
  const [tableObject, setTableObject] = useState({
    newValue: true,
    oldValue: true,
    Bid: true,
    Pid: true,
    Publisher: true,
    user: true,
    type: true,
    time: true,
  });
  const [isLoader, setIsLoader] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedOption, setSelectedOption] = useState("loss");

  const options = [
    { label: "Loss", value: "loss" },
    { label: "Profit", value: "profit" },
    { label: "A-Z", value: "alphabet" },
  ];

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    switch (
      selectedValue // Update sorting logic based on selected option
    ) {
      case "alphabet":
        setdata([...data].sort((a, b) => a.title.localeCompare(b.title))); // Sort data alphabetically
        break;
      case "profit":
        setdata([...data].sort((a, b) => b.value - a.value)); // Sort data by profit
        break;
      case "loss":
      default:
        setdata([...data].sort((a, b) => a.value - b.value)); // Sort data by loss
        break;
    }
  };

  useEffect(() => {
    async function getEntityData() {
      const entity_data = await DataObject.getEntityInfo();
      setEntity(() => entity_data);
    }
    getEntityData();
  }, []);

  useEffect(() => {
    async function getLogData() {
      const list_data = await DataObject.getLogData();
      if (list_data?.response?.data?.message === "Forbidden") {
        setNoLogData(true);
        return;
      }
      if (!list_data) {
        setNoLogData(true);
      } else {
        setLogData((prev) => [...prev, ...list_data?.refinedData]);
        setcount(list_data?.count)
        setlimit(list_data?.limit)
      }
    }
    getLogData();
  }, []);

  useEffect(() => {
    if (!isConfirmModalOpen && shutdownlogMsg.length) {
      setConfirmModalOpen(true);
    }
  }, [shutdownlogMsg]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      localStorage.removeItem("cachedData");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    const cached = localStorage.getItem("cachedData");
    if (cached) {
      setIsLoader(false);
      const { data, cakeData, auctionData } = JSON.parse(cached);
      setdata(data);
      setCakeData(cakeData);

      setAuctionData(auctionData);
      getData().then(() => {
        setIsLoading(false);
      });
      setIsLoading(true);
    } else {
      getData();
    }
  }, []);

  // const getData = async () => {
  //   const list_data = await DataObject.getData();
  //   if (list_data?.response?.data?.message === "KMD_NOT_FOUND") {
  //     navigator("/update-kmd", {
  //       state: {
  //         data: list_data?.response?.data?.userID,
  //       },
  //     });
  //     return;
  //   }
  //   console.log("list_data ==>", list_data);
  //   setdata(() => []);
  //   setAuctionData(() => []);
  //   setCakeData(() => []);
  //   if (list_data === false) {
  //     setNoData(true);
  //     return;
  //   }
  //   if (list_data?.success === false) {
  //     if (list_data?.isSignInRequired) {
  //       return navigator("/sign-in");
  //     }
  //     alert(list_data?.message || "Unknown Error");
  //     return;
  //   }

  //   if (!list_data?.list?.length) {
  //     setNoData(true);
  //   }
  //   let cakeDataRequired;
  //   let sorted_auction_data;
  //   const list_data_colored = list_data?.list?.map((item, index) => {
  //     return {
  //       title: item?.name,
  //       id: item?.id,
  //       value: Number(item?.value),
  //       winRate: item.winRate?.slice(0, 4) + "%",
  //       color: item.color,
  //       lightColor: item.lightColor,
  //     };
  //   });
  //   if (list_data_colored) {
  //     const sortedData = [...list_data_colored].sort((a, b) => a.value - b.value);
  //     setdata(sortedData);
  //     cakeDataRequired = list_data_colored.filter((item, i) => {
  //       return item.value > 0;
  //     });
  //     setCakeData((prev) => [...prev, ...cakeDataRequired]);
  //     sorted_auction_data = auctionSort(list_data_colored);
  //     setAuctionData((prev) => [...prev, ...sorted_auction_data]);
  //   }
  //   localStorage.setItem(
  //     "cachedData",
  //     JSON.stringify({
  //       data: list_data_colored,
  //       cakeData: cakeDataRequired,
  //       auctionData: sorted_auction_data,
  //     })
  //   );
  // };

  const getData = async () => {
    try {
      const list_data = await DataObject.getData();
  
      if (list_data?.response?.data?.message === "KMD_NOT_FOUND") {
        navigator("/update-kmd", {
          state: {
            data: list_data?.response?.data?.userID,
          },
        });
        return;
      }
  
      console.log("list_data ==>", list_data);
      if (list_data?.isSignInRequired) {
        navigator('/sign-in')
        return
      }
      if (!list_data?.list?.length) {
        setNoData(true);
        return;
      }
  
      let cakeDataRequired;
      let sorted_auction_data;
      const list_data_colored = list_data?.list?.map((item, index) => ({
        title: item?.name,
        id: item?.id,
        value: Number(item?.value),
        winRate: (item.winRate?.slice(0, 4) || '0') + "%",
        color: item.color,
        lightColor: item.lightColor,
        didShutDown:item.didShutDown || false
      }));
  
      // Sort the data based on loss initially
      const sortedData = [...list_data_colored].sort((a, b) => a.value - b.value);
      setdata(sortedData);
  
      cakeDataRequired = list_data_colored.filter(item => item.value > 0);
      setCakeData(cakeDataRequired);
  
      sorted_auction_data = auctionSort(list_data_colored);
      setAuctionData(sorted_auction_data);
  
      localStorage.setItem(
        "cachedData",
        JSON.stringify({
          data: sortedData,
          cakeData: cakeDataRequired,
          auctionData: sorted_auction_data,
        })
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const auctionSort = (arr) => {
    for (var i = 0; i < arr.length; i++) {
      for (var j = 0; j < arr.length - i - 1; j++) {
        if (
          Number(arr[j]?.winRate.slice(0, -1)) <
          Number(arr[j + 1]?.winRate.slice(0, -1))
        ) {
          var temp = arr[j];
          arr[j] = arr[j + 1];
          arr[j + 1] = temp;
        }
      }
    }
    return arr;
  };

  const ConfirmModal = ({ isOpen, close, shutdownlogMsg, totalCount }) => {
    if (!isOpen) {
      return null;
    }
    let percent = 0;
    let progressObject = {
      placementCount: 0,
      websiteCount: 0,
    };
    if (!shutdownlogMsg.length) {
      percent = 100;
      progressObject = {
        placementCount: totalCount,
        websiteCount: 0,
      };
    }
    
    if (shutdownlogMsg.length > 0) {
      progressObject = JSON.parse(shutdownlogMsg);
      percent =
        (Number(progressObject.placementCount) / Number(totalCount)) * 100;
    }

    return (
      <div className="overlay">
        <div className="modal-content">
          <p>Total: {totalCount}</p>
          <p>{`${progressObject.placementCount} placements shutdown out of ${totalCount}`}</p>
          <ProgressBar
            completed={Math.round(percent)}
            maxCompleted={100}
            bgColor="lightGreen"
            baseBgColor="#dedede"
          />
          {!shutdownlogMsg.length && (
            <div>
              <p>
                Shut down process completed{" "}
                <button
                  style={{ margin: "10px" }}
                  type="button"
                  onClick={() => close()}
                >
                  Close
                </button>
              </p>
            </div>
          )}
        </div>
      </div>
    );
  };

  if (noData === true) {
    return <h3 style={{ padding: "10px" }}>No data avilable</h3>;
  }
  return (
    <div>
      {isLoading && (
        <div class="notification">
          <div class="notification-content">
            <p>New data is being fetched from server</p>
          </div>
        </div>
      )}
      <div className="main">
        <section>
          <div class="dashboard-container">
            <ConfirmModal
              isOpen={isConfirmModalOpen}
              close={() => {
                setConfirmModalOpen(false);
              }}
              totalCount={totalCount}
              shutdownlogMsg={shutdownlogMsg}
            />
            <div class="d-flex justify-content-between align-items-baseline">
              <div class="dashboard-heading d-flex align-items-center">
                <span class="me-2">
                  <img src={barChart} alt="barChartImage" />
                </span>
                <span class="heading-text">Publishers Overview</span>
              </div>
              <div>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => setSlider(!slider)}
                  class="d-block view-all-link underline-effect"
                >
                  View all
                </span>
              </div>
            </div>
            
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "50px",
              }}
            >
            <select value={selectedOption} onChange={handleSelectChange} className="custom-select">
              {options.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
              <div style={{ width: 400 }}>
                <Autocomplete options={entity} fromHome={true} />
              </div>
            </div>
            {!slider && (
              <div class="card-container dashboard-view">
                <div class="card-row d-flex flex-wrap align-items-center">
                  {!data?.length && isLoader === true && (
                    <div
                      style={{
                        marginLeft: "calc(50% - 50px)",
                        marginRight: "auto",
                      }}
                    >
                      <ColorRing
                        colors={[
                          "#4fb9f0",
                          "#2474a4",
                          "#4fb9f0",
                          "#88d6ff",
                          "#4fb9f0",
                        ]}
                        height="100"
                        width="100"
                        color="#4fa94d"
                        ariaLabel="ColorRing-loading"
                        wrapperStyle={{}}
                        wrapperClass="wrapper-class"
                        visible={true}
                      />
                    </div>
                  )}
                  {data?.map((item, index) => {
                    
                    return (
                      <SSPCard
                        setshutdownlogMsg={setshutdownlogMsg}
                        setTotalCount={setTotalCount}
                        item={item}
                        level={level}
                        sspActive={item?.didShutDown}
                        color={item.color}
                        lightColor={item.lightColor}
                      />
                    );
                  })}
                </div>
              </div>
            )}

            {slider && (
              <div class="card-container">
                <div class="card-row d-flex align-items-center justify-content-start">
                  {!data?.length && isLoader === true && (
                    <div
                      style={{
                        marginLeft: "calc(50% - 50px)",
                        marginRight: "auto",
                      }}
                    >
                      <ColorRing
                        colors={[
                          "#4fb9f0",
                          "#2474a4",
                          "#4fb9f0",
                          "#88d6ff",
                          "#4fb9f0",
                        ]}
                        height="100"
                        width="100"
                        color="#4fa94d"
                        ariaLabel="ColorRing-loading"
                        wrapperStyle={{}}
                        wrapperClass="wrapper-class"
                        visible={true}
                      />
                    </div>
                  )}
                  {data?.map((item, index) => {
                 
                    return (
                      <SSPCard
                        setshutdownlogMsg={setshutdownlogMsg}
                        setTotalCount={setTotalCount}
                        item={item}
                        level={level}
                        color={item.color}
                        sspActive={item?.didShutDown}
                        lightColor={item.lightColor}
                      />
                    );
                  })}
                </div>
              </div>
            )}

            <div class="chart-container mt-4 mt-md-5">
              <div class="row">
                <div class="col-lg-6 mb-3 mb-md-4 mb-lg-0">
                  <div class="dashboard-heading d-flex align-items-center">
                    <span class="me-2">
                      <img src={barChart} alt="barChartImage" />
                    </span>
                    <span class="heading-text">Profits</span>
                  </div>

                  <div class="chart-box">
                    {!data?.length && isLoader === true && (
                      <div
                        style={{
                          marginLeft: "calc(50% - 50px)",
                          marginRight: "auto",
                        }}
                      >
                        <ColorRing
                          colors={[
                            "#4fb9f0",
                            "#2474a4",
                            "#4fb9f0",
                            "#88d6ff",
                            "#4fb9f0",
                          ]}
                          height="100"
                          width="100"
                          color="#4fa94d"
                          ariaLabel="ColorRing-loading"
                          wrapperStyle={{}}
                          wrapperClass="wrapper-class"
                          visible={true}
                        />
                      </div>
                    )}
                    {data?.length > 0 && <PieChart value={cakeData} />}
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="dashboard-heading d-flex align-items-center">
                    <span class="me-2">
                      <img src={barChart} alt="barChartImage" />
                    </span>
                    <span class="heading-text">Auctions won</span>
                  </div>

                  <div class="chart-box">
                    {!data?.length && isLoader === true && (
                      <div
                        style={{
                          marginLeft: "calc(50% - 50px)",
                          marginRight: "auto",
                        }}
                      >
                        <ColorRing
                          colors={[
                            "#4fb9f0",
                            "#2474a4",
                            "#4fb9f0",
                            "#88d6ff",
                            "#4fb9f0",
                          ]}
                          height="100"
                          width="100"
                          color="#4fa94d"
                          ariaLabel="ColorRing-loading"
                          wrapperStyle={{}}
                          wrapperClass="wrapper-class"
                          visible={true}
                        />
                      </div>
                    )}
                    {data?.length > 0 && (
                      <PieChart value={auctionData} auctions={true} />
                    )}
                  </div>
                </div>
              </div>
            </div>

            {!noLogData && (
              <div class="advertisment-info-table mt-5">
                <div class="dashboard-heading d-flex align-items-center">
                  <span class="me-2">
                    <img src={barChart} alt="barChartImage" />
                  </span>
                  <span class="heading-text">Protocol</span>
                </div>
                <div class="advertisment-table-container">
                  <div class="table-link-wrap">
                    <div class="link-btn-holder d-flex align-items-center pb-3">
                      <span
                        class={
                          tableObject.time
                            ? "table-link-btn active"
                            : "table-link-btn"
                        }
                        onClick={() =>
                          setTableObject({
                            ...tableObject,
                            ...{ time: !tableObject.time },
                          })
                        }
                      >
                        Time
                      </span>
                      <span
                        class={
                          tableObject.user
                            ? "table-link-btn active"
                            : "table-link-btn"
                        }
                        onClick={() =>
                          setTableObject({
                            ...tableObject,
                            ...{ user: !tableObject.user },
                          })
                        }
                      >
                        User
                      </span>
                      <span
                        class={
                          tableObject.Pid
                            ? "table-link-btn active"
                            : "table-link-btn"
                        }
                        onClick={() =>
                          setTableObject({
                            ...tableObject,
                            ...{ Pid: !tableObject.Pid },
                          })
                        }
                      >
                        Placements
                      </span>
                      <span
                        class={
                          tableObject.Publisher
                            ? "table-link-btn active"
                            : "table-link-btn"
                        }
                        onClick={() =>
                          setTableObject({
                            ...tableObject,
                            ...{ Publisher: !tableObject.Publisher },
                          })
                        }
                      >
                        Publisher
                      </span>
                      <span
                        class={
                          tableObject.Bid
                            ? "table-link-btn active"
                            : "table-link-btn"
                        }
                        onClick={() =>
                          setTableObject({
                            ...tableObject,
                            ...{ Bid: !tableObject.Bid },
                          })
                        }
                      >
                        Bid
                      </span>
                      <span
                        class={
                          tableObject.type
                            ? "table-link-btn active"
                            : "table-link-btn"
                        }
                        onClick={() =>
                          setTableObject({
                            ...tableObject,
                            ...{ type: !tableObject.type },
                          })
                        }
                      >
                        Type
                      </span>
                      <span
                        class={
                          tableObject.newValue
                            ? "table-link-btn active"
                            : "table-link-btn"
                        }
                        onClick={() =>
                          setTableObject({
                            ...tableObject,
                            ...{ newValue: !tableObject.newValue },
                          })
                        }
                      >
                        New value
                      </span>
                      <span
                        class={
                          tableObject.oldValue
                            ? "table-link-btn active"
                            : "table-link-btn"
                        }
                        onClick={() =>
                          setTableObject({
                            ...tableObject,
                            ...{ oldValue: !tableObject.oldValue },
                          })
                        }
                      >
                        Old value
                      </span>
                    </div>
                  </div>
                  <Table tableObject={tableObject} logData={logData} count={count} limit={limit}  />
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Home;
