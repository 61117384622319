import { useState } from "react";
import axios from 'axios'
import "../Templates/css/style.css";
import barChartOne from "../Templates/images/bar-chart-icon.svg";
import getUrl from "../../helpers/GetUrl";
import { useNavigate } from "react-router-dom";
const EditKMD = ({userID}) => {
    const navigate = useNavigate()
    const [kuser, setkuser] = useState('')
    const [kpassword, setkpassword] = useState('')

    const updateKMD = async () => {
        if (kuser?.trim()?.length === 0) {
            alert("K-User value is required")
            return
        }
        if (kpassword?.trim()?.length === 0) {
            alert("K-Password value is required")
            return
        }
       // console.log(userID)
        const url = getUrl() + "/api/user/management/update-own-kmd/userID".replace("userID", userID);
        const response = await axios.put(url, {kuser,kpassword})
        if (response?.data?.success) {
            alert("updated")
            navigate("/")
        } else {
            alert("failed to update please try again this is mandatory")
        }
        return
    }
    return (
        <section>
            <div class="dashboard-container">
                <div class="dashboard-heading d-flex align-items-center">
                    <span class="me-2">
                        <img src={barChartOne} alt="image" />
                    </span>
                    <span class="heading-text">  Edit user </span>
                </div>
                <div class="add-user-container">
                    <div class="row" style={{ display: "flex", justifyContent: "space-around" }}>
                        <div class="col-md-6 col-lg-4 col-xl-3">
                            <div class="mb-4">
                                <label for="name" class="form-label">
                                    K - User
                                </label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="username"
                                    placeholder="Enter K User"
                                    value={kuser}
                                    onChange={(e) => setkuser(e.target?.value)}
                                />
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 col-xl-3">
                            <div class="mb-4">
                                <label for="mail" class="form-label">
                                    K - Password
                                </label>
                                <input
                                    type="password"
                                    class="form-control"
                                    id="email"
                                    placeholder="Enter K Password"
                                    value={kpassword}
                                    onChange={(e) => setkpassword(e.target?.value)}
                                />
                            </div>
                        </div>
                        <div class="d-flex justify-content-center align-items-center mt-3 mt-md-4">
                            <button
                                class="save-btn"
                                // onSubmit={handleSubmit}
                                onClick={() => updateKMD()}
                            >
                                Create Unique Access Token
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default EditKMD;
