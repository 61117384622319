import PriceWrapper from "./PriceWrapper";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ColorRing } from 'react-loader-spinner'
import getUrl from "../../helpers/GetUrl";
const SSPCard = ({ item, level,sspActive, setshutdownlogMsg, setTotalCount,color }) => {

  const backendurl_bidding = getUrl()
  const navigate = useNavigate();

  const [isOn, setIsOn] = useState(sspActive);
  const [loading, setloading] = useState(false)

  let _shutdownlogId = 0
  

  const handleToggle = async (e) => {
    // if (localStorage?.getItem("auth-level") != "-1") {
    //   alert("You are not authorized for this action")
    //   e.stopPropagation();
    //   return
    // }
    setshutdownlogMsg("")
    e.stopPropagation()
    let countData = {}
    if (!isOn) {
      setloading(true)
      const countResp = await axios.get(`${backendurl_bidding}/api/bidding/get-count/${item?.id}`)
      countData = countResp?.data
    
      if (countData?.logID) {
  
        _shutdownlogId = countData.logID
      }
      setloading(false)
    }
    let message = ""
    if (!isOn) {
      message = `you are going to shut down ${countData?.placements} placements  from ${countData?.websites} websites`
    } else {
      message = "Are you sure to revert shut down"
    }
    confirmAlert({
      title: `Confirmation`,
      message: message,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            setTotalCount(countData?.placements)
            setIsOn(!isOn)
            
            if (!isOn) {
              startTimer()
              shutDownAPI(item);
            } else {
              deleteShutdown(item)
            }
          },
        },
        {
          label: 'No',
          onClick: () => {
           
          },
        },
      ],
    });
  };

  let timer;
  const startTimer = () => {
    if (!timer) {
      getShutDownLog()
      timer = setTimeout(() => {
        timer = null
        startTimer();
      }, 5 * 1000);
    }
  };

  async function getShutDownLog() {
    const response = await axios.get(`${backendurl_bidding}/api/bidding/get-log-status/${_shutdownlogId}`);
    if (response?.data?.countData) {
      const message = `currently ${response?.data?.countData?.placementCount} placements from ${response?.data?.countData?.websiteCount} websites had shut down`
      setshutdownlogMsg(JSON.stringify(response?.data?.countData))
   
    }
  }


  const shutDownAPI = async (item) => {
    // const placements = await fetch(`${backendurl_bidding}/api/bidding/shut-down/:id`.replace(":id",item.id))
   
    try {
      const location = window.location
      const response = await axios.post(
        `${backendurl_bidding}/api/bidding/shutdown-one`, {
            publisherID: item.id,
            publisherName: item.title,
            setToZero: "1",
            logID: _shutdownlogId,
            hostName:location.hostname,
            protocol:location.protocol
          });
      setshutdownlogMsg("")
      if (timer) {
        clearTimeout(timer);
      }
      
      if (response.data?.bids) {
        // console.log("Data sent successfully.");
      } else {
        console.error("Failed to send data to the API.");
      }
    } catch (error) {
      console.error("Error sending data to the API:", error);
    }
  };

  const deleteShutdown = async (item) => {
    try {
      const location = window.location
      const response = await fetch(
        `${backendurl_bidding}/api/bidding/remove-shutdown`,
        {
          method: "POST",
          credentials: 'include',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            publisherID: item.id,
            setToZero: "0",
            publisherName: item.title,
            hostName:location.hostname,
            protocol:location.protocol
          }),
        }
      );
      if (response.ok) {
        alert('Shutdown reverted successfully')
        // console.log("Data removed successfully.");
      } else {
        alert('Failed to revert ')
       // console.error("Failed to removed data.");
      }
    } catch (error) {
      console.error("Error sending data to the API:", error);
    }
  };

  const nextPage = () => {
    // if (level > 1 || level == -1) {
      navigate(
        "/publisher/name/id"
          .replace("id", item?.id)
          .replace("name", (item?.title).replace(" ", "_"))
      );
    // }
  };
  if (loading) {
    return (
      <div class="card-col" style={{ paddingLeft: "auto", paddingRight: "auto" }}>
        <ColorRing colors={['#4fb9f0', '#2474a4','#4fb9f0','#88d6ff','#4fb9f0' ]}
          height="100"
          width="100%"
          color="#4fa94d"
          ariaLabel="ColorRing-loading"
          wrapperStyle={{}}
          wrapperClass="wrapper-class"
          visible={true}
        />
      </div>)
  }
  return (
    <div class="card-col">
      <a onClick={() => nextPage()  } class="info-card d-block" style={{ cursor: "pointer" }}>
        <div class="ssp-holder d-flex align-items-center justify-content-between mb-2">
        <span class="ssp-name">{item?.id}</span>
          <br />
          <span class="ssp-name">{item?.title}</span>
          <div class="switch-wrap">
            <input
              type="checkbox"
              class="switch-check"
              checked={isOn}
              onClick={handleToggle}
            ></input>
            <label style={{ marginLeft: "2px" }} htmlFor="toggleSwitch">{isOn ? "OFF" : "ON"}</label>
          </div>
        </div>
        <div class="status-holder d-flex align-items-center justify-content-evenly">
          <div class="link-wrap d-flex justify-content-center align-items-center" style={{ backgroundColor: color }}>
            <span>
              <i class="bi bi-link-45deg" style={{ color: "white" }}></i>
            </span>
          </div>
          <div class="income-wrap d-flex flex-column">
            <span class="money-value mb-2">
              <PriceWrapper value={item.value} />
            </span>
            <span class="caption-text">Today’s Earnings</span>
          </div>
          <div class="value-wrap decrement d-flex align-items-center">
            <span></span>
            <span class="value-text"></span>
          </div>
        </div>
      </a>
    </div>
  );
}
export default SSPCard;