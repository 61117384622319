import barChart from "../Templates/images/bar-chart-icon.svg";
import "../Templates/css/style.css";
import PieChart from "./PieChartComponent";
import WebSiteCard from "./WebSiteCard";
import { useEffect, useState } from "react";
import DataObject from "./Datamanager";
import { ColorRing } from "react-loader-spinner";
import { useNavigate, useLocation } from "react-router-dom";
import Autocomplete from "./AutoComplete";
export default function () {
  const navigate = useNavigate();
  const location = useLocation();
  let name, pub_id;
  const parts = location?.pathname?.split("/");
  if (parts.length > 2) {
    name = parts[parts.length - 2]?.replace("%20", "-");
    pub_id = parts[parts.length - 1];
  }
  const colors = ["#607cfc", "#58dca4", "#f05464"];
  const [noData, setNoData] = useState(false);
  const [level, setlevel] = useState(localStorage?.getItem("auth-level") || 2);
  const [publisherName, setPublishername] = useState(name);
  const [publisherId, setPublisherId] = useState(pub_id);
  const [slider, setSlider] = useState(true);
  const [data, setdata] = useState([]);
  const [forbidden,setforbidden] = useState(false)
  const [cakeData, setCakeData] = useState([]);
  const [auctionData, setAuctionData] = useState([]);
  const [entity, setEntity] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("loss");

  const options = [
    { label: "Loss", value: "loss" },
    { label: "Profit", value: "profit" },
    { label: "A-Z", value: "alphabet" },
  ];

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    switch (
      selectedValue // Update sorting logic based on selected option
    ) {
      case "alphabet":
        setdata([...data].sort((a, b) => a.title.localeCompare(b.title))); // Sort data alphabetically
        break;
      case "profit":
        setdata([...data].sort((a, b) => b.value - a.value)); // Sort data by profit
        break;
      case "loss":
      default:
        setdata([...data].sort((a, b) => a.value - b.value)); // Sort data by loss
        break;
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      localStorage.removeItem("cachedWebData");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    const cachedUrl = localStorage.getItem("cachedUrl");
    const cached = localStorage.getItem("cachedWebData");
    if (cached && cachedUrl == window?.location?.pathname) {
      setIsLoader(false);
      const { data, cakeData, auctionData } = JSON.parse(cached);
      setdata(data);
      setCakeData(cakeData);
      setAuctionData(auctionData);
      getData().then(() => {
        setIsLoading(false);
      });
      setIsLoading(true);
    } else {
      getData();
    }
    localStorage.setItem("cachedUrl", window?.location?.pathname);
  }, []);

  

  // useEffect(() => {
  //   async function getEntityData() {
  //     const entity_data = await DataObject.getEntityInfo();

  //     // setEntity(() => entity_data);
  //     const entity_data_filtered = entity_data.filter((item, index) => {
  //       if (item?.type == "website" && item?.parentID == pub_id) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     });
  //     if (entity_data_filtered?.response?.data?.showForbidden) {
  //       setforbidden(true)
  //     }
  //     setEntity(() => entity_data_filtered);
  //     const names = entity_data.map((item, index) => {
  //       return item.name;
  //     });
  //   }
  //   getEntityData();
  // }, []);

    useEffect(() => {
      async function getEntityData() {
        const entity_data = await DataObject.getEntityInfo();
       // setEntity(() => entity_data);
        const entity_data_filtered = entity_data.filter((item, index) => {
          if (item?.type == "website" && item?.parentID == pub_id) {
            return true;
          } else {
            return false;
          }
        });
        setEntity(() => entity_data_filtered);
        const names = entity_data.map((item, index) => {
          return item.name;
        });
      }
      getEntityData();
    }, []);

  async function getData() {
    const list_data = await DataObject.getDataWS(publisherId);
    setdata(()=> [])
    setCakeData(()=> [])
    setAuctionData(()=> [])
    setlevel(list_data?.level);
    if (list_data?.response?.data?.message === 'KMD_NOT_FOUND') {
      navigate("/update-kmd", {
        state:{
          data: list_data?.response?.data?.userID
        }
      })
      return
    }
    if (list_data?.response?.data?.showForbidden) {
      setforbidden(true)
    }
    if (list_data?.isSignInRequired) {
      navigate("/sign-in");
      return;
    }
    if (list_data?.success === false) {
      alert(
        list_data?.message ||
          list_data?.data ||
          list_data?.error ||
          "unknown error"
      );
    }
    if (list_data?.success === false || list_data?.list.length === 0) {
      setNoData(true);
      return;
    }

    let cakeDataRequired;
      let sorted_auction_data;
    const list_data_colored = list_data?.list?.map((item, index) => {
      return {
        title: item?.name,
        value: Number(item?.value),
        winRate: item.winRate?.slice(0, 4) + "%",
        id: item?.id,
        color: item?.color,
        lightColor: item?.lightColor,
      };
    });
       // Sort the data based on loss initially
       const sortedData = [...list_data_colored].sort((a, b) => a.value - b.value);
       setdata(sortedData);
   
       cakeDataRequired = list_data_colored.filter(item => item.value > 0);
       setCakeData(cakeDataRequired);
   
       sorted_auction_data = auctionSort(list_data_colored);
       setAuctionData(sorted_auction_data);

    localStorage.setItem(
      "cachedWebData",
      JSON.stringify({
        data: list_data_colored,
        cakeData: cakeDataRequired,
        auctionData: sorted_auction_data,
      })
    );
  }

  function auctionSort(arr) {
    for (var i = 0; i < arr.length; i++) {
      for (var j = 0; j < arr.length - i - 1; j++) {
        if (
          Number(arr[j]?.winRate.slice(0, -1)) <
          Number(arr[j + 1]?.winRate.slice(0, -1))
        ) {
          var temp = arr[j];
          arr[j] = arr[j + 1];
          arr[j + 1] = temp;
        }
      }
    }
    return arr;
  }



  function auctionSort(arr) {
    for (var i = 0; i < arr.length; i++) {
      for (var j = 0; j < arr.length - i - 1; j++) {
        if (
          Number(arr[j]?.winRate.slice(0, -1)) <
          Number(arr[j + 1]?.winRate.slice(0, -1))
        ) {
          var temp = arr[j];
          arr[j] = arr[j + 1];
          arr[j + 1] = temp;
        }
      }
    }
    return arr;
  }

  if (forbidden) {
    return <h3>Forbidden</h3>
  }

  if (noData === true) {
    return <h3 style={{ padding: "10px" }}>No data available</h3>;
  }

  return (
    <div className="main">
      {isLoading && (
        <div class="notification">
          <div class="notification-content">
            <p>New data is being fetched from server</p>
          </div>
        </div>
      )}
      <section>
        <div class="dashboard-container">
          <div class="breadcrumb-set">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                    Home
                  </a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  {name.replaceAll("%20", " ") || ""}
                </li>
              </ol>
            </nav>
          </div>
          <select value={selectedOption} onChange={handleSelectChange} className="custom-select" style={{ textAlign: 'left' }} >
              {options.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "30px",
            }}
          >
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "flex-start",
                marginLeft: "20px",
                maxWidth: "100px",
                marginTop: "-50px",
                height: "30px",
                position: "absolute",
                right: "1",
              }}
            >
              {" "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setSlider(!slider)}
                class="d-block view-all-link underline-effect"
              >
                View all
              </span>
            </div>
            <div style={{ width: 400 }}>
              <Autocomplete options={entity} fromWebSite={publisherName} />
            </div>
          </div>
          {!slider && (
            <div class="card-container dashboard-view">
              <div class="card-row d-flex flex-wrap align-items-center">
                {!data?.length && (
                  <div
                    style={{
                      marginLeft: "calc(50% - 50px)",
                      marginRight: "auto",
                    }}
                  >
                    <ColorRing
                      colors={[
                        "#4fb9f0",
                        "#2474a4",
                        "#4fb9f0",
                        "#88d6ff",
                        "#4fb9f0",
                      ]}
                      height="100"
                      width="100"
                      color="#4fa94d"
                      ariaLabel="ColorRing-loading"
                      wrapperStyle={{}}
                      wrapperClass="wrapper-class"
                      visible={true}
                    />
                  </div>
                )}
                {data?.map((item, index) => {
                  return (
                    <WebSiteCard
                    item={item}
                    publisherName={publisherName}
                    publisherId={publisherId}
                    websiteMode={true}
                    level={level}
                    color={item.color}
                    lightColor={item.lightColor}
                    />
                  );
                })}
              </div>
            </div>
          )}
          {slider && (
            <div class="card-container">
              <div class="card-row d-flex align-items-center justify-content-start">
                {!data?.length && (
                  <div
                    style={{
                      marginLeft: "calc(50% - 50px)",
                      marginRight: "auto",
                    }}
                  >
                    <ColorRing
                      colors={[
                        "#4fb9f0",
                        "#2474a4",
                        "#4fb9f0",
                        "#88d6ff",
                        "#4fb9f0",
                      ]}
                      height="100"
                      width="100"
                      color="#4fa94d"
                      ariaLabel="ColorRing-loading"
                      wrapperStyle={{}}
                      wrapperClass="wrapper-class"
                      visible={true}
                    />
                  </div>
                )}
                {data?.map((item, index) => {
                  return (
                    <WebSiteCard
                      item={item}
                      publisherName={publisherName}
                      publisherId={publisherId}
                      websiteMode={true}
                      level={level}
                      color={item.color}
                      lightColor={item.lightColor}
                    />
                  );
                })}
              </div>
            </div>
          )}
          <div class="chart-container mt-4 mt-md-5">
            <div class="row">
              <div class="col-lg-6 mb-3 mb-md-4 mb-lg-0">
                <div class="dashboard-heading d-flex align-items-center">
                  <span class="me-2">
                    <img src={barChart} alt="image" />
                  </span>
                  <span class="heading-text">Profits</span>
                </div>

                <div class="chart-box">
                  {!data?.length && (
                    <div
                      style={{
                        marginLeft: "calc(50% - 50px)",
                        marginRight: "auto",
                      }}
                    >
                      <ColorRing
                        colors={[
                          "#4fb9f0",
                          "#2474a4",
                          "#4fb9f0",
                          "#88d6ff",
                          "#4fb9f0",
                        ]}
                        height="100"
                        width="100"
                        color="#4fa94d"
                        ariaLabel="ColorRing-loading"
                        wrapperStyle={{}}
                        wrapperClass="wrapper-class"
                        visible={true}
                      />
                    </div>
                  )}
                  {data?.length > 0 && <PieChart value={cakeData} />}
                </div>
              </div>

              <div class="col-lg-6">
                <div class="dashboard-heading d-flex align-items-center">
                  <span class="me-2">
                    <img src={barChart} alt="image" />
                  </span>
                  <span class="heading-text">Auctions won</span>
                </div>

                <div class="chart-box">
                  {!data?.length && (
                    <div
                      style={{
                        marginLeft: "calc(50% - 50px)",
                        marginRight: "auto",
                      }}
                    >
                      <ColorRing
                        colors={[
                          "#4fb9f0",
                          "#2474a4",
                          "#4fb9f0",
                          "#88d6ff",
                          "#4fb9f0",
                        ]}
                        height="100"
                        width="100"
                        color="#4fa94d"
                        ariaLabel="ColorRing-loading"
                        wrapperStyle={{}}
                        wrapperClass="wrapper-class"
                        visible={true}
                      />
                    </div>
                  )}
                  {data?.length > 0 && (
                    <PieChart value={auctionData} auctions={true} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
