function ChartTable({ tableObject, data }) {
        return (
                <div class="view-table mt-5">
                        <table class="table">
                                <thead>
                                        <tr>    
                                                {tableObject.Hour && <th>Hour</th>}
                                                {tableObject.Clicks && <th>Clicks</th>}
                                                {tableObject.View && <th>Views</th>}
                                                {tableObject.GrossProfit && <th>Rohertrag</th>}
                                                {tableObject.winRate && <th>Win Rate</th>}
                                                {tableObject.publisherSum && <th>Sum | (Publisher)</th>}
                                                {tableObject.etcpPublisher && <th>eTCP | (Publisher)</th>}
                                                {tableObject.answeredRequest && <th>Answered RTB requests</th>}
                                                {tableObject.responseTime && <th>Response time</th>}

                                        </tr>
                                </thead>
                                <tbody>
                                        {data?.map((item, index) => {
                                                return (
                                                        <tr>
                                                                {tableObject.Hour && <td>{item.key}</td>}
                                                                {tableObject.Clicks && <td>{item.Klicks}</td>}
                                                                {tableObject.View && <td>{item["Views"]}</td>}
                                                                {tableObject.GrossProfit && <td>{parseFloat(item["Rohertrag"]).toFixed(2)}</td>}
                                                                {tableObject.winRate && <td>{parseFloat(item["Win-Rate"]).toFixed(2)}</td>}
                                                                {tableObject.publisherSum && <td>{parseFloat(item["Summe | (Publisher)"]).toFixed(2)} Euro</td>}
                                                                {tableObject.etcpPublisher && <td>{parseFloat(item["eTKP | (Publisher)"]).toFixed(2)} Euro </td>}
                                                                {tableObject.answeredRequest && <td>{item["RTB-Requests"]}</td>}
                                                                {tableObject.responseTime && <td>{(parseFloat(item["Antwortzeit"])/1000).toFixed(2)}</td>}

                                                        </tr>
                                                )
                                        })}
                                </tbody>
                        </table>
                </div>
        )
}

export default ChartTable;
