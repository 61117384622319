import BarChartIcon from "./images/bar-chart-icon.svg";
import TableViewIcon from "./images/table-view-icon.svg";
import TableViewActiveIcon from "./images/table-view-active-icon.svg";
import ChartIcon from "./images/chart-icon.svg";
import ChartActiveIcon from "./images/chart-active-icon.svg";
import PieChart from "./PieChartComponent";
import ClockImage from "./images/clock.svg";
import SmartPhoneImage from "./images/smartphone.svg";
import VerticalMoreIcon from "./images/more-vertical.svg";
import BarChartWithRecharts from "./BarChartWithReCharts";
// import PublisherCard from "./publisherCard";
import { ColorRing } from "react-loader-spinner";
import WebSiteCard from "./WebSiteCard";
import _, { filter } from "lodash";
import DataObject from "./Datamanager";
import { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import addBoost from "../../services/BoostAdder";
import strCompare from "./strCompare.json";
import ChartTable from "./Charttable";

const Placement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();
  const [noData, setNoData] = useState(false);
  const [platformdata, setplatformdata] = useState([]);
  const [locationBoost, setLocationBoost] = useState([])
  const [hourlydata, sethourlydata] = useState([]);
  const [forbidden, setforbidden] = useState(false)
  const [hourlydataB, sethourlydataB] = useState([]);
  const [platformdataD, setplatformdataD] = useState([]);
  const [platformdataM, setplatformdataM] = useState([]);
  const [platformdataD_B, setplatformdataD_B] = useState([]);
  const [platformdataM_B, setplatformdataM_B] = useState([]);
  const [level, setlevel] = useState(3);
  const [loading, setloading] = useState(false)
  const [tableView, setTableView] = useState(true);
  const [data, setdata] = useState([]);
  const [cakeData, setCakeData] = useState([]);
  const [auctionData, setAuctionData] = useState([]);
  const [oldValue, setoldValue] = useState([]);
  const [sliderValue, setSliderValue] = useState(2);
  const [objectvalue, setobjectvalue] = useState([]);
  const [inputValues, setInputValues] = useState([]); // Initialize with empty values for 3 fields
  const [strValues, setCompareValues] = useState([]);
  const [buttonValue, setButtonValue] = useState("0"); // State to store the button value
  const [chartData, setChartData] = useState([]);
  const [hourlyBoostData, setHourlyBoostData] = useState([]);
  const [placementID, setPlacementID] = useState(null);
  const [selectedPlacementName, setSelectedPlacementName] = useState("");
  const [websiteID, setWebsiteID] = useState("");
  const [publisher, setPublisher] = useState("");
  const [website, setWebsite] = useState("");
  const [publisherId, setPublisherId] = useState("");
  const [chartLoading, setChartLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [hasTableData, setHasTableData] = useState(false);
  const [hasBoostData, setHasBoostData] = useState(false);
  const [boostHourly, setBoostHourly] = useState([]);
  const [boostDataLoading, setBoostDataLoading] = useState(false);
  const [showboostupdate, setshowboostupdate] = useState(true);
  const [day, setDay] = useState("Today");
  const [logData, setLogData] = useState();
  const [filteredLogData, setFilteredLogData] = useState();
  const [lastPlacementID, setLastPlacementID] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([
    "boost",
    "rohertrag",
  ]);
  const [bulkvalue, setbulkvalue] = useState(0);
  const [bulValueAlterChecked, setBulValueAlterChecked] = useState(false);
  const [abroadBoost, setAbroadBoost] = useState(0)
  const [noConsentOsBoost, setNoConsentOsBoost] = useState(0)
  const [noConsentOsBoostB, setNoConsentOsBoostB] = useState(0)
  const [noConsentOsBoostBid, setNoConsentOsBoostBid] = useState(-1)
  const [unknownOsBoost, setUnknownOsBoost] = useState(0)
  const [unknownOsBoostB, setUnknownOsBoostB] = useState(0)
  const [unknownOsBoostBid, setUnknownOsBoostBid] = useState(-1)
  const [tableObject, setTableObject] = useState({
    Hour: true,
    Clicks: true,
    View: true,
    publisherSum: true,
    AdvertisementSum: true,
    GrossProfit: true,
    etcpPublisher: true,
    etcpAdvertiser: true,
    answeredRequest: true,
    requests: true,
    bidCpm: true,
    winRate: true,
    responseTime: true,
  });
  // const [sliderValue, setSliderValue] = useState(2);
  const [selectedOption, setSelectedOption] = useState("loss");

  const options = [
    { label: "Loss", value: "loss" },
    { label: "Profit", value: "profit" },
    { label: "A-Z", value: "alphabet" },
  ];

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    switch (
    selectedValue // Update sorting logic based on selected option
    ) {
      case "alphabet":
        setdata([...data].sort((a, b) => a.title.localeCompare(b.title))); // Sort data alphabetically
        break;
      case "profit":
        setdata([...data].sort((a, b) => b.value - a.value)); // Sort data by profit
        break;
      case "loss":
      default:
        setdata([...data].sort((a, b) => a.value - b.value)); // Sort data by loss
        break;
    }
  };

  useEffect(() => {
    if (placementID) {
      // Clear the timer whenever placementID changes
      if (timer) {
        clearTimeout(timer);
      }

      // Start the initial timer
      startTimer();

      return () => {
        // Clear the timer when the component unmounts
        if (timer) {
          clearTimeout(timer);
        }
      };
    }
  }, [placementID, lastPlacementID]);


  useEffect(() => {
    async function getData() {
      const url_parts = location?.pathname?.split("/");
      let website_id = "";
      let placement_id = "";
      let pub_id = "";
      let pub_name = "";
      let website_name = "";
      if (url_parts?.length > 3) {
        website_name = url_parts[url_parts?.length - 5];
        if (website_name === "placements") {
          website_name = url_parts[url_parts?.length - 4];
          pub_name = url_parts[url_parts?.length - 2];
          pub_id = url_parts[url_parts?.length - 1];
          website_id = url_parts[url_parts?.length - 3];
        } else {
          pub_name = url_parts[url_parts?.length - 3];
          pub_id = url_parts[url_parts?.length - 2];
          website_id = url_parts[url_parts?.length - 4];
          placement_id = url_parts[url_parts?.length - 1];
        }
        setPublisher(pub_name);
        setWebsite(website_name);
        setPublisherId(pub_id);
      }
      const id = url_parts[url_parts?.length - 1];
      setWebsiteID(id);
      const list_data = await DataObject.getDataPlacements(website_id);
      if (list_data?.isSignInRequired) {
        navigate("/sign-in");
        return;
      }
      if (list_data?.response?.data?.message === 'KMD_NOT_FOUND') {
        navigate("/update-kmd", {
          state: {
            data: list_data?.response?.data?.userID
          }
        })
        return
      }
      if (list_data?.response?.data?.showForbidden) {
        setforbidden(true)
      }
      if (list_data?.success === false) {
        alert(
          list_data?.message ||
          list_data?.data ||
          list_data?.error ||
          "unknown error"
        );
      }
      if (list_data?.success === false || list_data?.list.length === 0) {
        setNoData(true);
        return;
      }
      setlevel(list_data.level);
      const logData = await DataObject.getLogData();

      setLogData(logData);

      let cakeDataRequired;
      let sorted_auction_data;
      const list_data_colored = list_data?.list?.map((item, index) => {
        return {
          title: item?.name,
          value: Number(item?.value),
          winRate: item.winRate?.slice(0, 4) + "%",
          id: item?.id,
          color: item?.color,
          lightColor: item?.lightColor,
        };
      });
      // Sort the data based on loss initially
      const sortedData = [...list_data_colored].sort((a, b) => a.value - b.value);
      setdata(sortedData);
      // setdata((prev) => [...prev, ...list_data_colored]);
      if (placement_id != "") {
        const currentPlacementName = list_data_colored.find(
          (obj) => obj.id === placement_id
        );

        const name = currentPlacementName?.title || ""
        handleCardSelect(placement_id, name, day);
      }

      cakeDataRequired = list_data_colored.filter(item => item.value > 0);
      setCakeData(cakeDataRequired);

      sorted_auction_data = auctionSort(list_data_colored);
      setAuctionData(sorted_auction_data);
      // const cakeDataRequired = list_data_colored.filter((item, i) => {
      //   return item.value > 0;
      // });
      // setCakeData((prev) => [...prev, ...cakeDataRequired]);
      // const sorted_auction_data = auctionSort(list_data_colored);
      // setAuctionData((prev) => [...prev, ...sorted_auction_data]);
    }
    getData();
  }, []);

  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    margin: "20px",
    textAlign: "left", // Align the content to the left
  };

  const questionStyle = {
    marginBottom: "10px",
  };

  const radioGroupStyle = {
    display: "flex",
    flexDirection: "column",
  };

  const radioButtonStyle = {
    marginBottom: "5px",
  };

  const onBulkBlur = (event) => {
    const arr = [];
    for (let x = 0; x < 24; x++) {
      const hourlyboostObj = hourlydata[x];
      const hourlyboostObjOld = hourlydataB[x];
      const object = {
        intValue: hourlyboostObj.intBoost,
        strValue: String(x),
        oldValue: hourlyboostObjOld[0]?.intBoost,
        bid: hourlyboostObj.intID,
        type: "0",
      };
      arr.push(object);
    }
    return arr;
  };
  const handleBulkChange = (event) => {
    if (!isNumeric(event?.target?.value)) {
      return;
    }
    setbulkvalue(event.target.value);
    const arr = [];
    setobjectvalue([]);
    for (let x of hourlydata) {
      const intValue = Number(event.target.value);
      const obj = { ...x, intBoost: intValue };
      arr.push(obj);
    }
    sethourlydata((prev) => arr);
  };

  function sliderBlur(e, platform) {

    const elem = e.target;
    const value = elem?.value;
    if (elem?.id == 'no-consent') {
      const object = {
        intValue: elem.value,
        strValue: '67108864',
        oldValue: noConsentOsBoostB,
        bid: noConsentOsBoostBid,
        type: "2",
      };
      setobjectvalue((prev) => [...prev, object]);
    }
    if (elem?.id == 'unknown-os') {
      const object = {
        intValue: elem.value,
        strValue: '1',
        oldValue: unknownOsBoostB,
        bid: unknownOsBoostBid,
        type: "2",
      };
      setobjectvalue((prev) => [...prev, object]);
    }
    const arr_os_obj = [
      { name: "unknown", value: "1" },
      { name: "Windows Desktop XP", value: "2" },
      { name: "Linux (1)", value: "4" },
      { name: "Apple Mac OS", value: "8" },
      { name: "SymbianOS (1)", value: "16" },
      { name: "iOS 10", value: "32" },
      { name: "SymbianOS (2)", value: "128" },
      { name: "Windows Mobile", value: "256" },
      { name: "Blackberry", value: "512" },
      { name: "iOS 11", value: "1024" },
      { name: "Windows Desktop 8", value: "2048" },
      { name: "Windows Desktop 7", value: "4096" },
      { name: "Windows Desktop Vista", value: "8192" },
      { name: "Windows Desktop 10", value: "16384" },
      { name: "Android 8", value: "65536" },
      { name: "Android 4", value: "262144" },
      { name: "iOS 8", value: "524288" },
      { name: "iOS 9", value: "1048576" },
      { name: "Linux (2)", value: "2097152" },
      { name: "Linux (3)", value: "4194304" },
      { name: "iOS 12", value: "8388608" },
      { name: "Android 9", value: "16777216" },
      { name: "Android 10", value: "32768" },
      { name: "Android 11", value: "64" },
      { name: "Android 12 + above", value: "131072" },
      { name: "iOS 13", value: "33554432" },
      { name: "iOS 14", value: "134217728" },
      { name: "iOS 15", value: "268435456" },
    ];
    for (let z of [
      "Android",
      "iOS",
      "Symbian",
      "Windows Mobile",
      "Windows Desktop",
      "Linux",
      "Mac OS",
    ]) {
      if (elem?.name == z) {
        for (let x of arr_os_obj) {
          if (x?.name.includes(z)) {
            const strCompare = x?.value;

            let flag = false;
            for (let y of platformdata) {
              if (strCompare == y?.strCompare) {
                flag = true;

                const object = {
                  intValue: value,
                  strValue: strCompare,
                  oldValue: y?.intBoost,
                  bid: y?.intID,
                  type: "2",
                };
                setobjectvalue((prev) => [...prev, object]);
              }
            }
            if (!flag) {
              const object = {
                intValue: value,
                strValue: strCompare,
                oldValue: 0,
                bid: -1,
                type: "2",
              };
              setobjectvalue((prev) => [...prev, object]);
            }
          }
        }
      }
    }
  }

  function handleSliderChange(e, platform, i) {
    let arr = [];

    if (e.target.id == 'no-consent') {
      setNoConsentOsBoost(e.target.value)

    }
    if (e.target.id == 'unknown-os') {
      setUnknownOsBoost(e.target.value)

    }
    if (e.target.id == "win_all") {
      arr = arr = JSON.parse(JSON.stringify(platformdataD));
      for (let x of [1, 3, 6, 7, 8, 9]) {
        const item = platformdataD[x];
        arr.splice(x, 1, {
          value: e.target.value,
          bid: item.bid,
          intType: "2",
          strValue: item.strValue,
          name: item?.name,
        });
      }
      setplatformdataD(arr);
      return;
    }
    if (e.target.id == "lin_all") {
      arr = arr = JSON.parse(JSON.stringify(platformdataD));
      for (let x of [2, 4, 10, 11]) {
        const item = platformdataD[x];
        arr.splice(x, 1, {
          value: e.target.value,
          bid: item.bid,
          intType: "2",
          strValue: item.strValue,
          name: item?.name,
        });
      }
      setplatformdataD(arr);
      return;
    }
    if (e.target.id == "andr_all") {
      arr = arr = JSON.parse(JSON.stringify(platformdataM));
      for (let x of [1, 6, 11, 12, 13, 14]) {
        const item = platformdataM[x];

        arr.splice(x, 1, {
          value: e.target.value,
          bid: item?.bid,
          intType: "2",
          strValue: item?.strValue,
          name: item?.name,
        });
      }
      setplatformdataM(arr);
      return;
    }
    if (e.target.id == "ios_all") {
      arr = arr = JSON.parse(JSON.stringify(platformdataM));
      for (let x of [3, 5, 10, 15, 16, 17, 19, 20, 21]) {
        const item = platformdataM[x];

        arr.splice(x, 1, {
          value: e.target.value,
          bid: item?.bid,
          intType: "2",
          strValue: item?.strValue,
          name: item?.name,
        });
      }
      setplatformdataM(arr);
      return;
    }
    if (e.target.id == "sym_all") {
      arr = arr = JSON.parse(JSON.stringify(platformdataM));
      for (let x of [2, 4, 7]) {
        const item = platformdataM[x];

        arr.splice(x, 1, {
          value: e.target.value,
          bid: item?.bid,
          intType: "2",
          strValue: item?.strValue,
          name: item?.name,
        });
      }
      setplatformdataM(arr);
      return;
    }
    if (e.target.id == "mob_all") {
      arr = arr = JSON.parse(JSON.stringify(platformdataM));
      for (let x of [
        0, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      ]) {
        const item = platformdataM[x];

        arr.splice(x, 1, {
          value: e.target.value,
          bid: item?.bid,
          intType: "2",
          strValue: item?.strValue,
          name: item?.name,
        });
      }
      setplatformdataM(arr);
      return;
    }
    if (e.target.id == "dt_all") {
      arr = arr = JSON.parse(JSON.stringify(platformdataD));
      for (let x of [0, 3, 4, 5, 6, 7, 8, 9, 10, 11]) {
        const item = platformdataD[x];

        arr.splice(x, 1, {
          value: e.target.value,
          bid: item?.bid,
          intType: "2",
          strValue: item?.strValue,
          name: item?.name,
        });
      }
      setplatformdataD(arr);
      return;
    }

    if (platform == "mobile") {
      arr = JSON.parse(JSON.stringify(platformdataM));
      arr.splice(i, 1, {
        value: e.target.value,
        bid: e.target?.getAttribute("boost_id"),
        intType: "2",
        strValue: e.target?.id,
        name: e.target?.name,
      });
      setplatformdataM(arr);
    } else {
      arr = arr = JSON.parse(JSON.stringify(platformdataD));
      arr.splice(i, 1, {
        value: e.target.value,
        bid: e.target?.getAttribute("boost_id"),
        intType: "2",
        strValue: e.target?.id,
        name: e.target?.name,
      });
      setplatformdataD(arr);
    }
  }

  function setFullBoostData(arr) {
    const houry_data = arr.filter((e, i) => {
      return e.intType == 0;
    });
    const os_wise_data = arr.filter((e, i) => {
      return e.intType == 2;
    });
    const locationData = arr.filter((e, i) => {
      return e.intType == 1;
    });
    setLocationBoost(() => locationData)
    if (locationData?.length) {
      setAbroadBoost(locationData[0]?.intBoost)
    } else {
      setAbroadBoost(0)
    }
    const noConsentOsData = arr.filter((e, i) => {
      return ((e.intType == 2) && (e.strCompare == '67108864'));
    });
    const unknownOsData = arr.filter((e, i) => {
      return (e.intType == 2 && e.strCompare == 1);
    });
    if (noConsentOsData.length) {
      setNoConsentOsBoost(noConsentOsData[noConsentOsData?.length - 1]?.intBoost)
      setNoConsentOsBoostB(noConsentOsData[noConsentOsData?.length - 1]?.intBoost)
      setNoConsentOsBoostBid(noConsentOsData[noConsentOsData?.length - 1]?.intID)
    } else {
      setNoConsentOsBoost(0)
      setNoConsentOsBoostB(0)
    }
    if (unknownOsData.length) {
      setUnknownOsBoost(unknownOsData[unknownOsData?.length - 1]?.intBoost)
      setUnknownOsBoostB(unknownOsData[unknownOsData?.length - 1]?.intBoost)
      setUnknownOsBoostBid(unknownOsData[unknownOsData?.length - 1]?.intID)
    } else {
      setUnknownOsBoost(0)
      setUnknownOsBoostB(0)
    }
    setplatformdata(os_wise_data);
    let display_arr_hour = [];

    for (let i = 0; i < 24; i++) {
      let flag = false;
      for (let item of houry_data) {
        if (item?.strCompare == i && item?.strCompare != "") {
          display_arr_hour.push(item);
          flag = true;
        }
      }
      if (!flag) {
        display_arr_hour.push({
          intBoost: "100",
          intID: "-1",
          intType: "0",
          strCompare: String(i),
        });
      }
    }
    const dt_os_list = [
      { name: "All", value: "0", strValue: "dt_all" },
      { name: "Windows_ALL", value: "0", strValue: "win_all" },
      { name: "Linux_ALL", value: "0", strValue: "lin_all" },
    ];
    const m_os_list = [
      { name: "All", value: "0", strValue: "mob_all" },
      { name: "Android_ALL", value: "0", strValue: "andr_all" },

      { name: "Sybion ALL", value: "0", strValue: "sym_all" },
      { name: "ios all", value: "0", strValue: "ios_all" },
    ];
    const m_os_new = [
      { name: "Android", value: 100 },
      { name: "Symbian", value: 100 },
      { name: "iOS", value: 100 },
      { name: "Windows Mobile", value: 100 },
    ];
    const dt_os_new = [
      { name: "Windows Desktop", value: 100 },
      { name: "Linux", value: 100 },
      { name: "Mac OS", value: 100 },
    ];
    const uniqueData = _.uniqBy(display_arr_hour, "strCompare");
    sethourlydata(uniqueData);
    sethourlydataB(uniqueData);
    const arr_os_obj = {
      1: { name: "unknown", mobile: undefined },
      2: { name: "Windows XP", mobile: false },
      4: { name: "Linux (1)", mobile: false },
      8: { name: "Apple Mac", mobile: false },
      16: { name: "SymbianOS (1)", mobile: true },
      32: { name: "iOS 10", mobile: true },

      128: { name: "SymbianOS (2)", mobile: true },
      256: { name: "Windows Mobile", mobile: true },
      512: { name: "Blackberry", mobile: true },
      1024: { name: "iOS 11", mobile: true },
      2048: { name: "Windows 8", mobile: false },
      4096: { name: "Windows 7", mobile: false },
      8192: { name: "Windows Vista", mobile: false },
      16384: { name: "Windows 10", mobile: false },

      65536: { name: "Android 8", mobile: true },

      262144: { name: "Android 4", mobile: true },
      524288: { name: "iOS 8", mobile: true },
      1048576: { name: "iOS 9", mobile: true },
      2097152: { name: "Linux (2)", mobile: false },
      4194304: { name: "Linux (3)", mobile: false },
      8388608: { name: "iOS 12", mobile: true },
      16777216: { name: "Android 9", mobile: true },
      32768: { name: "Android 10", mobile: true },
      64: { name: "Android 11", mobile: true },
      131072: { name: "Android 12 + above", mobile: true },
      33554432: { name: "iOS 13", mobile: true },
      67108864: { name: "(No Consent)", moblie: undefined },
      134217728: { name: "iOS 14", mobile: true },
      268435456: { name: "iOS 15", mobile: true },

      128: { name: "SymbianOS (2)", mobile: true },
      256: { name: "Windows Mobile", mobile: true },
      512: { name: "Blackberry", mobile: true },
      1024: { name: "iOS 11", mobile: true },
      2048: { name: "Windows 8", mobile: false },
      4096: { name: "Windows 7", mobile: false },
      8192: { name: "Windows Vista", mobile: false },
      16384: { name: "Windows 10", mobile: false },

      65536: { name: "Android 8", mobile: true },

      262144: { name: "Android 4", mobile: true },
      524288: { name: "iOS 8", mobile: true },
      1048576: { name: "iOS 9", mobile: true },
      2097152: { name: "Linux (2)", mobile: false },
      4194304: { name: "Linux (3)", mobile: false },
      8388608: { name: "iOS 12", mobile: true },
      16777216: { name: "Android 9", mobile: true },
      32768: { name: "Android 10", mobile: true },
      64: { name: "Android 11", mobile: true },
      131072: { name: "Android 12 + above", mobile: true },
      33554432: { name: "iOS 13", mobile: true },
      67108864: { name: "(No Consent)", moblie: undefined },
      134217728: { name: "iOS 14", mobile: true },
      268435456: { name: "iOS 15", mobile: true },
    };
    let arr_os = Object.keys(arr_os_obj);
    let arr_os_dt = arr_os.filter((e, i) => arr_os_obj[e]?.mobile == false);
    let arr_os_mob = arr_os.filter((e, i) => arr_os_obj[e]?.mobile == true);
    const os_wise_data_new = [];
    for (let i in os_wise_data) {
      const item = os_wise_data[i];
      const strCompares = os_wise_data_new.map((e, i) => e.strCompare);
      if (strCompares.includes(item?.strCompare)) {
        const pos = strCompares.indexOf(item?.strCompare);
        os_wise_data_new.splice(pos, 1);
      }
      os_wise_data_new.push(item);
    }
    for (let x of os_wise_data_new) {
      const strCompare = x.strCompare;
      if (strCompare == "131072") {
        m_os_new[0] = {
          name: "Android",
          value: x?.intBoost,
        };
      }
      if (strCompare == "128") {
        m_os_new[1] = {
          name: "Symbian",
          value: x?.intBoost,
        };
      }
      if (strCompare == "8388608") {
        m_os_new[2] = {
          name: "iOS",
          value: x?.intBoost,
        };
      }
      if (strCompare == "256") {
        m_os_new[3] = {
          name: "Windows Mobile",
          value: x?.intBoost,
        };
      }
    }
    // Windows Mobile
    for (let x of os_wise_data_new) {
      const strCompare = x.strCompare;
      if (strCompare == "16384") {
        dt_os_new[0] = {
          name: "Windows Desktop",
          value: x?.intBoost,
        };
      }
      if (strCompare == "4") {
        dt_os_new[1] = {
          name: "Linux",
          value: x?.intBoost,
        };
      }
      if (strCompare == "8") {
        dt_os_new[2] = {
          name: "Mac OS",
          value: x?.intBoost,
        };
      }
    }
    const m_os_new_uniq = _.uniqBy(m_os_new, "name");
    const dt_os_new_uniq = _.uniqBy(dt_os_new, "name");
    setplatformdataM(m_os_new_uniq);
    setplatformdataM_B(m_os_new_uniq);
    setplatformdataD(dt_os_new_uniq);
    setplatformdataD_B(dt_os_new_uniq);

    return;
    for (let elem of arr_os_dt) {
      let flag = false;
      for (let item of os_wise_data) {
        if (String(item.strCompare) == String(elem)) {
          flag = true;
          const obj_m_data = {
            name: arr_os_obj[elem]?.name,
            bid: item?.intID,
            value: item?.intBoost,
            strValue: elem,
          };
          dt_os_list.push(obj_m_data);
        }
      }
      if (!flag) {
        dt_os_list.push({
          name: arr_os_obj[elem]?.name,
          bid: "-1",
          value: "100",
          strValue: elem,
        });
      }
    }

    for (let elem of arr_os_mob) {
      let flag = false;
      for (let item of os_wise_data) {
        if (String(item.strCompare) == String(elem)) {
          flag = true;
          const obj_m_data = {
            name: arr_os_obj[elem]?.name,
            bid: item?.intID,
            value: item?.intBoost,
            strValue: elem,
          };
          m_os_list.push(obj_m_data);
          break;
        }
      }
      if (!flag) {
        m_os_list.push({
          name: arr_os_obj[elem]?.name,
          bid: "-1",
          value: "100",
          strValue: elem,
        });
      }
    }
    //setplatformdataM(m_os_list);
    // setplatformdataM_B(m_os_list);
    setplatformdataD(dt_os_list);
    setplatformdataD_B(dt_os_list);
    return;
    const display_arr_os = [];
    for (let i of arr_os) {
      for (let item of os_wise_data) {
        if (item?.strCompare == i) {
          display_arr_os.push(item);
        } else {
          display_arr_os.push({
            intID: "-1",
            intType: "2",
            strCompare: String(i),
            intBoost: "100",
          });
        }
      }
    }
  }

  const updateLocationBoost = () => {

    if (locationBoost?.length === 0) {
      const newObject = {
        intValue: abroadBoost,
        strValue: "!DE",
        bid: -1,
        type: "1",
      }
      setobjectvalue((prev) => [...prev, newObject])
    } else {
      const newObject = {
        intValue: abroadBoost,
        strValue: "!DE",
        oldValue: locationBoost[0].intValue,
        bid: locationBoost[0].intID || -1,
        type: "1",
      }
      setobjectvalue((prev) => [...prev, newObject])
    }
  }

  function auctionSort(arr) {
    for (var i = 0; i < arr.length; i++) {
      for (var j = 0; j < arr.length - i - 1; j++) {
        if (
          Number(arr[j]?.winRate.slice(0, -1)) <
          Number(arr[j + 1]?.winRate.slice(0, -1))
        ) {
          var temp = arr[j];
          arr[j] = arr[j + 1];
          arr[j + 1] = temp;
        }
      }
    }
    return arr;
  }

  function sortBoostData(data) {
    const result = [];

    // Create an object to store the boost data for each hour (strCompare from 0 to 23)
    const boostDataByHour = {};

    // Initialize the boostDataByHour object with empty arrays for each hour
    for (let hour = 0; hour <= 23; hour++) {
      boostDataByHour[hour] = [];
    }

    // Iterate through your data and populate the boostDataByHour object
    data.forEach((item) => {
      const strCompare = parseInt(item.strCompare);
      const hour = strCompare >= 0 && strCompare <= 23 ? strCompare : -1;
      // const createdAtDate = new Date(item.createdAt);

     // Convert the date to German time
      // createdAtDate.setTime(createdAtDate.getTime() + (createdAtDate.getTimezoneOffset() * 60 * 1000) + (2 * 60 * 60 * 1000)); // Convert to German time (GMT +2)

      // Format the date and time components
      // const formattedDate = `${createdAtDate.getDate().toString().padStart(2, '0')}/${(createdAtDate.getMonth() + 1).toString().padStart(2, '0')}/${createdAtDate.getFullYear()}`;
      // const formattedTime = `${createdAtDate.getHours().toString().padStart(2, '0')}:${createdAtDate.getMinutes().toString().padStart(2, '0')}`;

      const formattedDate = item["createdAtDate"]
      const formattedTime = item["createdAtTime"].trim()

      if (hour !== -1) {
        boostDataByHour[hour].push({
          Hour: hour < 10 ? `0${hour}:00` : `${hour}:00`,
          Boost: parseInt(item.boost),
          OldBoost: parseInt(item.lastBoost),
          DotValue: parseFloat(item.id),
          Date: formattedDate,
          createdTime: formattedTime
        });
      }
    });

    // Create the result array by flattening the boostDataByHour object
    // for (let hour = 0; hour <= 23; hour++) {
    //   result.push(...boostDataByHour[hour]);
    // }
    return boostDataByHour;
  }

  async function getLogDataByPID(iD) {
    const PidWiseLog = await DataObject.getBidLogByPID(iD)
    if (logData?.response?.refinedData?.alertForbidden) {
      alert('Forbidden')
    }
    if (!PidWiseLog || PidWiseLog?.length == 0) {
      return;
    }
    setFilteredLogData(sortBoostData(PidWiseLog));
  }

  function handleDateChange(cardId, day) {
    setTableLoading(true);
    setChartLoading(true);
    getLogDataByPID(cardId)
    getTableAndChartData(websiteID, cardId, day);
  }

  const periodicRefreshFunction = () => {
    getTableAndChartData(websiteID, placementID, day);
    startTimer(); // Restart the timer after each call
  };

  let timer;

  // Function to start the 10-minute timer for periodic refresh
  const startTimer = () => {
    if (!timer) {
      timer = setTimeout(() => {
        if (placementID === lastPlacementID) {
          periodicRefreshFunction();
        }
        setLastPlacementID(placementID); // Update lastPlacementID when the timer expires
        timer = null;
        startTimer(); // Restart the timer
      }, 10 * 60 * 1000); // 10 minutes
    }
  };

  function handleCardSelect(cardId, name, day) {
    // if (level < 4 && level > 0) {
    //   return;
    // }
    setTableLoading(true);
    setChartLoading(true);
    setBoostDataLoading(true);
    setPlacementID(cardId);
    setSelectedPlacementName(name);
    setChartData(null);
    setHourlyBoostData(null);

    getTableAndChartData(websiteID, cardId, day);
    getBoostData(cardId);
    getLogDataByPID(cardId);
  }

  async function getTableAndChartData(websiteID, cardId, day) {
    if (day === "Today") day = "today"
    else if (day === "Yesterday") day = "yesterday"
    else if (day === "Day before yesterday") day = "dayBefore"
    DataObject.getTableAndChartData(websiteID, cardId, day).then(
      (data_response) => {
        if (data_response?.response?.data?.alertForbidden) {
          alert("Forbidden")
          setChartLoading(false);
          setChartLoading(false);
          setTableLoading(false);
          return
        }
        const data = data_response?.data;
        if (data !== undefined && data !== null) {
          const arrayOfObjects = [];
          for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
              const newObj = {
                key: key,
                ...data[key],
              };
              arrayOfObjects.push(newObj);
            }
          }

          setHasTableData(Object.keys(arrayOfObjects).length > 1);
          setChartLoading(false);
          setChartLoading(false);
          setTableLoading(false);
          setChartData(arrayOfObjects);
          return arrayOfObjects;
        }
      }
    );
  }

  async function getBoostData(cardId) {
    const response = await DataObject.getBoostHourlyData(cardId)

    if (response?.response?.data?.alertForbidden) {
      setBoostDataLoading(false);
      setshowboostupdate(false)
      return
    }
    const data = response?.data
    handleReceivedBoostData(data);
    prepareDataForChart(data)
    setHourlyBoostData(data);
    setFullBoostData(data);
    setBoostDataLoading(false);
  }

  function handleDefaultDateButtonClick(id, name) {
    setDay("Today");
    handleCardSelect(id, name, day);
  }

  function bubbleSort(arr, prop) {
    let swapped;
    do {
      swapped = false;
      for (let i = 0; i < arr.length - 1; i++) {
        if (arr[i][prop] > arr[i + 1][prop]) {
          // Swap the elements
          [arr[i], arr[i + 1]] = [arr[i + 1], arr[i]];
          swapped = true;
        }
      }
    } while (swapped);
  }

  function prepareDataForChart(data) {
    let boostArray = [];
    for (let i = 0; i < 24; i++) {
      let value;
      if (data[i] && data[i]["intType"] == "0") {
        value = data[i]["intBoost"].toString();
      } else {
        value = "100";
      }
      boostArray?.push(value);
    }

    let filteredBoost = data.filter((item) => {
      return item.intType == "0"
    })

    let filterInputs = filteredBoost.filter((item, index) => {
      if (item?.strCompare == "" || !item?.strCompare) {
        return false;
      }
      return true;
    });

    // bubbleSort(filterInputs, "strCompare");
    filterInputs.sort((a, b) => parseInt(a["strCompare"]) - parseInt(b["strCompare"]))

    const filterInputsStrVals = filterInputs.map((item, index) => {
      return item?.intBoost;
    });
    setBoostHourly(filterInputsStrVals)

  }

  function handleReceivedBoostData(data) {
    let boostArray = [];
    for (let i = 0; i < 24; i++) {
      let value;
      if (data[i]) {
        value = data[i]["intBoost"].toString();
      } else {
        value = "100";
      }
      boostArray?.push(value);
    }

    setHourlyBoostData(boostArray);

    let filterInputs = data.filter((item, index) => {
      if (item?.strCompare == "" || !item?.strCompare) {
        return false;
      }
      return true;
    });

    bubbleSort(filterInputs, "strCompare");

    const filterInputsStrVals = filterInputs.map((item, index) => {
      return item?.intBoost;
    });
    setInputValues(filterInputsStrVals);
  }

  const toggleView = (isTable) => {
    setTableView(isTable);
  };

  // Event handler for the "Time" button click
  const handleTimeButtonClick = () => {
    setButtonValue("0"); // Set the value for the "Time" button
  };

  // Event handler for the "Platform" button click
  const handlePlatformButtonClick = () => {
    setButtonValue("2"); // Set the value for the "Platform" button
  };
  // Event handler for the "Platform" button click
  const handleLocationButtonClick = () => {
    setButtonValue("4"); // Set the value for the "Platform" button
  };
  // Event handler for the "Others" button click
  const handleOthersButtonClick = () => {
    setButtonValue("3"); // Set the value for the "Others" button
  };
  function isNumeric(str) {
    return /^\d+$/.test(str);
  }
  // Update inputValues array as the user types in the input fields
  const handleInputChange = (event, index) => {
    // const intValue = parseInt(event.target.value, 10); // Convert the input value to an integer
    // const strValue = parseInt(event.target.id);
    if (!isNumeric(event.target.value)) {
      return;
    }
    const intValue = event.target.value; // Convert the input value to an integer
    const strValue = event.target.id;
    const boostId = event.target.getAttribute("boost_id");
    timeSlotChange(intValue, strValue, boostId, index);
  };

  const timeSlotChange = (intValue, strValue, boostId, index) => {
    let pos = Number(strValue);
    const oldValue = hourlydata?.filter((elem, index) => {
      if (String(strValue) === String(elem?.strCompare)) {
        return true;
      } else {
        return false;
      }
    });
    let mutableCopyArr = JSON.parse(JSON.stringify(hourlydata));
    mutableCopyArr.splice(pos, 1, {
      intBoost: intValue,
      intID: boostId,
      intType: "0",
      strCompare: strValue,
    });
    sethourlydata(mutableCopyArr);
    let object = {};
    if (oldValue?.length) {
      object = {
        intValue,
        strValue,
        oldValue: oldValue[0]?.intBoost,
      };
    } else {
      object = {
        intValue,
        strValue,
      };
    }

    // setobjectvalue((prev) => [...prev, object])

    if (!isNaN(intValue) && strValue !== "null") {
      // Check if the conversion was successful and it's a valid integer

      const newInputValues = [...inputValues];

      // const newInputValues = [intValue];
      newInputValues[index] = intValue;
      setInputValues(newInputValues);

      const newCompareValues = [...strValues];
      newCompareValues[index] = strValue;
      setCompareValues(newCompareValues);
    }
  };


  const handleBulkChangeBlur = (event) => {

    for (let i = 0; i < 24; i++) {
      const strValue = String(i);
      let valueExists = -1;
      for (let x in objectvalue) {
        const item = objectvalue[x];
        if (item?.strValue == strValue) {
          valueExists = x;
        }
      }
      if (valueExists > -1) {
        objectvalue.splice(valueExists, 1);
      }
      const intValue = event.target.value;
      const bid = hourlydata[i].intID;
      const oldValue = hourlydataB?.filter((elem, index) => {
        if (String(strValue) === String(elem?.strCompare)) {
        }
        return String(strValue) === String(elem?.strCompare);
      });
      let object = {};
      if (oldValue?.length) {
        object = {
          intValue,
          strValue,
          oldValue: oldValue[0]?.intBoost,
          bid: bid,
          type: "0",
        };
      } else {
        object = {
          intValue,
          strValue,
          bid,
          type: "0",
        };
      }

      setobjectvalue((prev) => [...prev, object]);
    }

  }

  const handleBlur = (event) => {
    const strValue = event.target.getAttribute("id");
    let valueExists = -1;
    for (let x in objectvalue) {
      const item = objectvalue[x];
      if (item?.strValue == strValue) {
        valueExists = x;
      }
    }
    if (valueExists > -1) {
      objectvalue.splice(valueExists, 1);
    }
    const intValue = event.target.value;
    const bid = event.target.getAttribute("boost_id");
    const oldValue = hourlydataB?.filter((elem, index) => {
      if (String(strValue) === String(elem?.strCompare)) {
      }
      return String(strValue) === String(elem?.strCompare);
    });
    let object = {};
    if (oldValue?.length) {
      object = {
        intValue,
        strValue,
        oldValue: oldValue[0]?.intBoost,
        bid: bid,
        type: "0",
      };
    } else {
      object = {
        intValue,
        strValue,
        bid,
        type: "0",
      };
    }

    setobjectvalue((prev) => [...prev, object]);
  };

  const getSliderValue = (value, name) => {
    const windowsXPValue = strCompare[name.replace(":", "").replace(" ", "")];
    const intValue = value;
    const strValue = windowsXPValue;
    setInputValues((prevValues) => [...prevValues, intValue]);
    setCompareValues((prevValues) => [...prevValues, strValue]);
  };

  function removeDuplicatesKeepLast(arr, prop) {
    const ids = new Set();
    return arr.reverse().filter((item) => {
      const duplicate = ids.has(item[prop]);
      ids.add(item[prop]);
      return !duplicate;
    });
  }

  function handleToggleSelection(type) {
    if (selectedOptions.includes(type)) {
      const updatedOptions = selectedOptions.filter(
        (option) => option !== type
      );
      setSelectedOptions(updatedOptions);
    } else {
      const updatedOptions = [...selectedOptions, type];
      setSelectedOptions(updatedOptions);
    }
  }

  const boostData = async () => {

    let objectValue = objectvalue.filter((element, index) => {
      return (element?.oldValue !== element?.intValue)
    });

    if (!objectValue?.length) {
      return
    }

    // if (bulValueAlterChecked) {
    //   const bultTimeSlotUpdate = onBulkBlur();
    //   objectValue = [...objectvalue, ...bultTimeSlotUpdate];
    // }

    const filteredInputValues = inputValues.filter(
      (value) => value !== undefined
    );
    const filteredCompareValues = strValues.filter(
      (value) => value !== undefined
    );
    const url = window?.location?.href;

    const body = {
      pid: placementID,
      bid: -1,
      type: buttonValue,
      compare: filteredCompareValues,
      boost: filteredInputValues,
      kmd: "8143af9411c2e397498fd2435d01a18695d9ce0c246e71f23b03e2353d8d0ff0",
      redirectionUrl: url,
    };
    // const url = "${backendurl}/api/setBoost";

    const values = [];
    for (let item of body?.compare) {
      const value = hourlyBoostData?.filter(
        (elem, index) => String(item) === String(elem?.strCompare)
      );

      if (value.length) {
        const oldBoost = value[0]?.intBoost;

        values.push(oldBoost);
        setoldValue((prev) => [...prev, oldBoost]);
      }
    }
    const deepCopiedArray = _.cloneDeep(objectValue);
    const uniqueArray = removeDuplicatesKeepLast(deepCopiedArray, "strValue");
    let modified_body = {
      ...body,
      ...{
        b: values,
        publisher,
        publisherId,
        selectedPlacementName,
        objectvalue: uniqueArray,
      },
    };
    if (bulValueAlterChecked) {
      modified_body = {
        ...body,
        ...{
          b: values,
          publisher,
          publisherId,
          selectedPlacementName,
          objectvalue: objectValue,
        },
      };
    }
    setloading(true)
    try {
      const response = await addBoost(modified_body, url);
      // Check if the response is successful
      if (response) {
        getBoostData(placementID)
        getLogDataByPID(placementID)
        if (day == "Today") {
          handleDateChange(placementID, "today")
        }
      }
    } catch (error) {
      console.error("API call failed:", error);
    } finally {
      setloading(false);
      // Reset values
      setobjectvalue(() => []);
      setInputValues(() => []);
      setCompareValues(() => []);
      setoldValue(() => []);
    }
  };
  const moveToWebsiteList = () => {

    const url_dest = '/publisher/' + location?.pathname.split("/")[location?.pathname.split("/")?.length - 2] + "/" + location?.pathname.split("/")[location?.pathname.split("/")?.length - 1]
    navigate(url_dest)
  }
  if (forbidden) {
    return <h3>Forbidden</h3>
  }
  if (noData === true) {
    return <h3 style={{ padding: "10px" }}>No data available</h3>;
  }
  return (
    <section>
      <div class="dashboard-container">
        <div class="breadcrumb-set">
          <nav aria-label="breadcrumb">
            {state?.fromHome && <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                  Home
                </a>
              </li>


              <li class="breadcrumb-item active" aria-current="page">
                {decodeURIComponent(website).replaceAll("%20", "-")}
              </li>
            </ol>}
            {(!state?.fromHome && !state?.fromWebSite) && <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a style={{ cursor: "pointer" }} onClick={() => navigate(-2)}>
                  Home
                </a>

              </li>
              <li class="breadcrumb-item">
                <a style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                  {decodeURIComponent(publisher).replaceAll("%20", "-")}
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {decodeURIComponent(website).replaceAll("%20", "-")}
              </li>
            </ol>}
            {(state?.fromWebSite && !state?.fromHome) && <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a style={{ cursor: "pointer" }} onClick={() => navigate(-2)}>
                  Home
                </a>
              </li>

              <li class="breadcrumb-item">
                <a style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                  {decodeURIComponent(state?.fromWebSite).replaceAll("%20", "-")}
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {decodeURIComponent(website).replaceAll("%20", "-")}
              </li>
            </ol>}
          </nav>
        </div>
        <select value={selectedOption} onChange={handleSelectChange} className="custom-select" style={{ textAlign: 'left' }} >
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div class="card-container dashboard-view">
          <div class="card-row d-flex flex-wrap align-items-center">
            {!data?.length && (
              <div
                style={{
                  marginLeft: "calc(50% - 50px)",
                  marginRight: "auto",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                <ColorRing
                  colors={[
                    "#4fb9f0",
                    "#2474a4",
                    "#4fb9f0",
                    "#88d6ff",
                    "#4fb9f0",
                  ]}
                  height="100"
                  width="100"
                  color="#4fa94d"
                  ariaLabel="ColorRing-loading"
                  wrapperStyle={{}}
                  wrapperClass="wrapper-class"
                  visible={true}
                />
              </div>
            )}
            {data?.map((item, index) => {
              return (
                <WebSiteCard
                  key={item.id} // Make sure to add a unique key prop when mapping
                  item={item}
                  websiteMode={false}
                  isSelected={item.id === placementID}
                  color={item.color}
                  lightColor={item.lightColor}
                  onClick={() =>
                    handleDefaultDateButtonClick(item.id, item.title)
                  }
                />
              );
            })}
          </div>
        </div>

        <div class="chart-container mt-4 mt-md-5">
          <div class="row">
            <div class="col-lg-6 mb-3 mb-md-4 mb-lg-0">
              <div class="dashboard-heading d-flex align-items-center">
                <span class="me-2">
                  <img src={BarChartIcon} alt="BarChartIcon" />
                </span>
                <span class="heading-text">Profits</span>
              </div>

              <div class="chart-box">
                {!data?.length && (
                  <div
                    style={{
                      marginLeft: "calc(50% - 50px)",
                      marginRight: "auto",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                    }}
                  >
                    <ColorRing
                      colors={[
                        "#4fb9f0",
                        "#2474a4",
                        "#4fb9f0",
                        "#88d6ff",
                        "#4fb9f0",
                      ]}
                      height="100"
                      width="100"
                      color="#4fa94d"
                      ariaLabel="ColorRing-loading"
                      wrapperStyle={{}}
                      wrapperClass="wrapper-class"
                      visible={true}
                    />
                  </div>
                )}
                {cakeData?.length > 0 && <PieChart value={cakeData} />}
              </div>
            </div>

            <div class="col-lg-6">
              <div class="dashboard-heading d-flex align-items-center">
                <span class="me-2">
                  <img src={BarChartIcon} alt="BarChartIcon" />
                </span>
                <span class="heading-text">Auctions won</span>
              </div>

              <div class="chart-box">
                {!data?.length && (
                  <div
                    style={{
                      marginLeft: "calc(50% - 50px)",
                      marginRight: "auto",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                    }}
                  >
                    <ColorRing
                      colors={[
                        "#4fb9f0",
                        "#2474a4",
                        "#4fb9f0",
                        "#88d6ff",
                        "#4fb9f0",
                      ]}
                      height="100"
                      width="100"
                      color="#4fa94d"
                      ariaLabel="ColorRing-loading"
                      wrapperStyle={{}}
                      wrapperClass="wrapper-class"
                      visible={true}
                    />
                  </div>
                )}
                {data?.length > 0 && (
                  <PieChart value={auctionData} auctions={true} />
                )}
              </div>
            </div>
          </div>
        </div>

        <div>
          {(placementID != null) && <div class="ad-info-view-wrap mt-5">
            <div class="d-flex flex-wrap justify-content-between align-items-center mb-4 pb-2">
              <div class="dashboard-heading d-flex align-items-center">
                <span class="me-2">
                  <img src={BarChartIcon} alt="BarChartIcon" />
                </span>
                <span class="heading-text">{selectedPlacementName}</span>
              </div>
              <div class="add-option-view mt-4 mt-lg-0 d-flex justify-content-between">
                <div class="btn-group me-2">
                  <button
                    type="button"
                    class="btn btn-default dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {day}
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        class="dropdown-item"
                        onClick={(e) => {
                          e.preventDefault();
                          setDay("Today");
                          handleDateChange(placementID, "today");
                        }}
                        href="/"
                      >
                        Today
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        onClick={(e) => {
                          e.preventDefault();
                          setDay("Yesterday");
                          handleDateChange(placementID, "yesterday");
                        }}
                        href="/"
                      >
                        Yesterday
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        onClick={(e) => {
                          e.preventDefault();
                          setDay("Day before yesterday");
                          handleDateChange(placementID, "dayBefore");
                        }}
                        href="/"
                      >
                        The day before yesterday
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <a
                    href="/"
                    class="view-btn me-2"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleView(true);
                    }}
                  >
                    <img
                      src={tableView ? TableViewActiveIcon : TableViewIcon}
                      alt="TableView Icon"
                    />
                  </a>
                  <a
                    href="/"
                    class="view-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleView(false);
                    }}
                  >
                    <img
                      src={tableView ? ChartIcon : ChartActiveIcon}
                      alt="ChartView Icon"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div class="result-view-container mb-4 mb-md-5">
              {tableView ? (
                <div>
                  {tableLoading ? (
                    <div
                      style={{
                        marginLeft: "calc(50% - 50px)",
                        marginRight: "auto",
                        paddingTop: "20px",
                        paddingBottom: "20px",
                      }}
                    >
                      <ColorRing
                        colors={[
                          "#4fb9f0",
                          "#2474a4",
                          "#4fb9f0",
                          "#88d6ff",
                          "#4fb9f0",
                        ]}
                        height="100"
                        width="100"
                        color="#4fa94d"
                        ariaLabel="ColorRing-loading"
                        wrapperStyle={{}}
                        wrapperClass="wrapper-class"
                        visible={true}
                      />
                    </div>
                  ) : (
                    <div>
                      {hasTableData ? (
                        <div class="advertisment-info-table">
                          <div class="advertisment-table-container">
                            <div class="table-link-wrap">
                              <div class="link-btn-holder d-flex align-items-center pb-3">
                                {/* <span class={tableObject.Hour ? "table-link-btn active" : "table-link-btn"} onClick={() => setTableObject({ ...tableObject, ...{ Hour: !tableObject.Hour } })}>Hour</span>
                                      <span class={tableObject.View ? "table-link-btn active" : "table-link-btn"} onClick={() => setTableObject({ ...tableObject, ...{ View: !tableObject.View } })}>View</span> */}
                                <span
                                  class={
                                    tableObject.Hour
                                      ? "table-link-btn active"
                                      : "table-link-btn"
                                  }
                                  style={{ marginTop: "15px" }}
                                  onClick={() =>
                                    setTableObject({
                                      ...tableObject,
                                      ...{ Hour: !tableObject.Hour },
                                    })
                                  }
                                >
                                  Hour
                                </span>
                                <span
                                  class={
                                    tableObject.Clicks
                                      ? "table-link-btn active"
                                      : "table-link-btn"
                                  }
                                  style={{ marginTop: "15px" }}
                                  onClick={() =>
                                    setTableObject({
                                      ...tableObject,
                                      ...{ Clicks: !tableObject.Clicks },
                                    })
                                  }
                                >
                                  Clicks
                                </span>
                                <span
                                  class={
                                    tableObject.View
                                      ? "table-link-btn active"
                                      : "table-link-btn"
                                  }
                                  style={{ marginTop: "15px" }}
                                  onClick={() =>
                                    setTableObject({
                                      ...tableObject,
                                      ...{ View: !tableObject.View },
                                    })
                                  }
                                >
                                  Views
                                </span>
                                <span
                                  class={
                                    tableObject.GrossProfit
                                      ? "table-link-btn active"
                                      : "table-link-btn"
                                  }
                                  style={{ marginTop: "15px" }}
                                  onClick={() =>
                                    setTableObject({
                                      ...tableObject,
                                      ...{ GrossProfit: !tableObject.GrossProfit },
                                    })
                                  }
                                >
                                  Rohertrag
                                </span>
                                <span
                                  class={
                                    tableObject.winRate
                                      ? "table-link-btn active"
                                      : "table-link-btn"
                                  }
                                  style={{ marginTop: "15px" }}
                                  onClick={() =>
                                    setTableObject({
                                      ...tableObject,
                                      ...{ winRate: !tableObject.winRate },
                                    })
                                  }
                                >
                                  Win Rate
                                </span>
                                {/* <span class={tableObject.AdvertisementSum ? "table-link-btn active" : "table-link-btn"} onClick={() => setTableObject({ ...tableObject, ...{ AdvertisementSum: !tableObject.AdvertisementSum } })}>Sum | Advertiser</span> */}
                                <span
                                  style={{ marginTop: "15px" }}
                                  class={
                                    tableObject.publisherSum
                                      ? "table-link-btn active"
                                      : "table-link-btn"
                                  }
                                  onClick={() =>
                                    setTableObject({
                                      ...tableObject,
                                      ...{
                                        publisherSum:
                                          !tableObject.publisherSum,
                                      },
                                    })
                                  }
                                >
                                  Sum | Publisher
                                </span>
                                {/* <span class={tableObject.GrossProfit ? "table-link-btn active" : "table-link-btn"} onClick={() => setTableObject({ ...tableObject, ...{ GrossProfit: !tableObject.GrossProfit } })}>Gross Profit</span>
                                      <span class={tableObject.etcpAdvertiser ? "table-link-btn active" : "table-link-btn"} onClick={() => setTableObject({ ...tableObject, ...{ etcpAdvertiser: !tableObject.etcpAdvertiser } })}>eTCP | Advertiser</span> */}
                                <span
                                  style={{ marginTop: "15px" }}
                                  class={
                                    tableObject.etcpPublisher
                                      ? "table-link-btn active"
                                      : "table-link-btn"
                                  }
                                  onClick={() =>
                                    setTableObject({
                                      ...tableObject,
                                      ...{
                                        etcpPublisher:
                                          !tableObject.etcpPublisher,
                                      },
                                    })
                                  }
                                >
                                  eCPM | Publisher
                                </span>
                                {/* <span class={tableObject.requests ? "table-link-btn active" : "table-link-btn"} onClick={() => setTableObject({ ...tableObject, ...{ requests: !tableObject.requests } })}>RTB Requests</span> */}
                                <span
                                  style={{ marginTop: "15px" }}
                                  class={
                                    tableObject.answeredRequest
                                      ? "table-link-btn active"
                                      : "table-link-btn"
                                  }
                                  onClick={() =>
                                    setTableObject({
                                      ...tableObject,
                                      ...{
                                        answeredRequest:
                                          !tableObject.answeredRequest,
                                      },
                                    })
                                  }
                                >
                                  Answered RTB requests
                                </span>
                                {/* <span class={tableObject.bidCpm ? "table-link-btn active" : "table-link-btn"} onClick={() => setTableObject({ ...tableObject, ...{ bidCpm: !tableObject.bidCpm } })}>Bid eCPM</span>
                                      <span class={tableObject.winRate ? "table-link-btn active" : "table-link-btn"} onClick={() => setTableObject({ ...tableObject, ...{ winRate: !tableObject.winRate } })}>Win rate</span> */}
                                <span
                                  style={{ marginTop: "15px" }}
                                  class={
                                    tableObject.responseTime
                                      ? "table-link-btn active"
                                      : "table-link-btn"
                                  }
                                  onClick={() =>
                                    setTableObject({
                                      ...tableObject,
                                      ...{
                                        responseTime:
                                          !tableObject.responseTime,
                                      },
                                    })
                                  }
                                >
                                  Response time
                                </span>
                              </div>
                            </div>

                            {chartData?.length && (
                              <ChartTable
                                tableObject={tableObject}
                                data={chartData}
                              />
                            )}

                            {/* <div class="view-table mt-5">
                                  <table class="table">
                                      <thead>
                                          <tr>
                                              {tableObject.Clicks && <th>Clicks</th>}
                                              {tableObject.publisherSum && <th>Sum | (Publisher)</th>}
                                              {tableObject.etcpPublisher && <th>eTCP | (Publisher)</th>}
                                              {tableObject.answeredRequest && <th>Answered RTB requests</th>}
                                              {tableObject.responseTime && <th>Response time</th>}
  
                                          </tr>
                                      </thead>
                                      <tbody>
                                          {[0, 0, 0, 0, 0].map((item, index) => {
                                              return (
                                                  <tr>
                                                      {tableObject.Clicks && <td>20</td>}
                                                      {tableObject.publisherSum && <td>0.96 Euro</td>}
                                                      {tableObject.etcpPublisher && <td>0.25 </td>}
                                                      {tableObject.answeredRequest && <td>97390 </td>}
                                                      {tableObject.responseTime && <td>49.95 </td>}
                              
                                                  </tr>
                                              )
                                          })}
                                      </tbody>
                                  </table>
                              </div> */}
                          </div>
                        </div>
                      ) : (
                        <div class="result-view-chart d-flex justify-content-center align-items-center">
                          <h4>No data</h4>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  {chartLoading ? (
                    <div
                      style={{
                        marginLeft: "calc(50% - 50px)",
                        marginRight: "auto",
                        paddingTop: "20px",
                        paddingBottom: "20px",
                      }}
                    >
                      <ColorRing
                        colors={[
                          "#4fb9f0",
                          "#2474a4",
                          "#4fb9f0",
                          "#88d6ff",
                          "#4fb9f0",
                        ]}
                        height="100"
                        width="100"
                        color="#4fa94d"
                        ariaLabel="ColorRing-loading"
                        wrapperStyle={{}}
                        wrapperClass="wrapper-class"
                        visible={true}
                      />
                    </div>
                  ) : (
                    <div>
                      {hasTableData ? (
                        <div class="result-view-chart d-flex align-items-center">
                          {chartData && hourlyBoostData && (
                            <BarChartWithRecharts
                              lineData={chartData.slice(1)}
                              boostData={boostHourly}
                              logData={filteredLogData}
                              boostSelected={selectedOptions.includes(
                                "boost"
                              )}
                              rohertragSelected={selectedOptions.includes(
                                "rohertrag"
                              )}
                              viewsSelected={selectedOptions.includes(
                                "views"
                              )}
                              winRateSelected={selectedOptions.includes(
                                "winRate"
                              )}
                              currentDay={day}
                            />
                          )}
                          <div
                            style={containerStyle}
                            className="data-selection-container"
                          >
                            <div style={questionStyle} className="question">
                              <p>Select Data</p>
                            </div>

                            <div
                              style={radioGroupStyle}
                              className="radio-group"
                            >
                              <label
                                style={radioButtonStyle}
                                className="radio-button"
                              >
                                <input
                                  type="checkbox"
                                  name="dataSelection"
                                  value="boost"
                                  checked={selectedOptions.includes("boost")}
                                  onChange={() =>
                                    handleToggleSelection("boost")
                                  }
                                />{" "}
                                Boosts
                              </label>
                              <label
                                style={radioButtonStyle}
                                className="radio-button"
                              >
                                <input
                                  type="checkbox"
                                  name="dataSelection"
                                  value="rohertrag"
                                  checked={selectedOptions.includes(
                                    "rohertrag"
                                  )}
                                  onChange={() =>
                                    handleToggleSelection("rohertrag")
                                  }
                                />{" "}
                                Rohertrag
                              </label>
                              <label
                                style={radioButtonStyle}
                                className="radio-button"
                              >
                                <input
                                  type="checkbox"
                                  name="dataSelection"
                                  value="views"
                                  checked={selectedOptions.includes("views")}
                                  onChange={() =>
                                    handleToggleSelection("views")
                                  }
                                />{" "}
                                Views
                              </label>
                              {/* <label
                                  style={radioButtonStyle}
                                  className="radio-button"
                                >
                                  <input
                                    type="checkbox"
                                    name="dataSelection"
                                    value="winRate"
                                    checked={selectedOptions.includes(
                                      "winRate"
                                    )}
                                    onChange={() =>
                                      handleToggleSelection("win-rate")
                                    }
                                  />{" "}
                                  Win Rate
                                </label> */}
                              <label
                                style={radioButtonStyle}
                                className="radio-button"
                              >
                                <input
                                  type="checkbox"
                                  name="dataSelection"
                                  value="winRate"
                                  checked={selectedOptions.includes(
                                    "winRate"
                                  )}
                                  onChange={() =>
                                    handleToggleSelection("winRate")
                                  }
                                />
                                Win Rate
                              </label>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div class="result-view-chart d-flex justify-content-center align-items-center">
                          <h4>No data</h4>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>}
        </div>

        <div>
          {(placementID != null) && (showboostupdate) && (
            <div>
              {boostDataLoading ? (
                <div
                  style={{
                    marginLeft: "calc(50% - 50px)",
                    marginRight: "auto",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  <ColorRing
                    colors={[
                      "#4fb9f0",
                      "#2474a4",
                      "#4fb9f0",
                      "#88d6ff",
                      "#4fb9f0",
                    ]}
                    height="100"
                    width="100"
                    color="#4fa94d"
                    ariaLabel="ColorRing-loading"
                    wrapperStyle={{}}
                    wrapperClass="wrapper-class"
                    visible={true}
                  />
                </div>
              ) : (
                <div class="bid-area-container mt-5">
                  <ul
                    class="nav nav-pills d-flex justify-content-center align-items-center mb-5"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li
                      class="nav-item me-md-4 mb-3 mb-md-0"
                      role="presentation"
                    >
                      <button
                        class="nav-link active d-flex align-items-center justify-content-center"
                        id="pills-time-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-time"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                        onClick={handleTimeButtonClick} // Set the click handler for the "Time" button

                      // value="1"
                      >
                        <img src={ClockImage} alt="icon" class="me-2" />
                        Time
                      </button>
                    </li>

                    <li
                      class="nav-item me-md-4 mb-3 mb-md-0"
                      role="presentation"
                    >
                      <button
                        class="nav-link d-flex align-items-center justify-content-center"
                        id="pills-platform-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-platform"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                        onClick={handlePlatformButtonClick} // Set the click handler for the "Platform" button
                      >
                        <img src={SmartPhoneImage} alt="icon" class="me-2" />
                        Platform
                      </button>
                    </li>
                    <li
                      class="nav-item me-md-4 mb-3 mb-md-0"
                      role="presentation"
                    >
                      <button
                        class="nav-link d-flex align-items-center justify-content-center"
                        id="pills-platform-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-location"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      // onClick={handlePlatformButtonClick} // Set the click handler for the "Platform" button
                      >
                        <img src={VerticalMoreIcon} alt="icon" class="me-2" />
                        Location
                      </button>
                    </li>

                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link d-flex align-items-center justify-content-center"
                        id="pills-others-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-others"
                        type="button"
                        role="tab"
                        aria-controls="pills-contact"
                        aria-selected="false"
                        onClick={handleOthersButtonClick} // Set the click handler for the "Others" button
                      >
                        <img src={VerticalMoreIcon} alt="icon" class="me-2" />
                        Others
                      </button>
                    </li>
                  </ul>

                  <div class="tab-content" id="pills-tabContent">
                    <div
                      class="tab-pane time-wrap fade show active"
                      id="pills-time"
                      role="tabpanel"
                      aria-labelledby="pills-time-tab"
                    >
                      {" "}
                      <div
                        style={{
                          width: "400px",
                          marginTop: "15px",
                          marginBottom: "15px",
                          marginLeft: "auto",
                          marginRight: "auto",
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <label style={{ paddingTop: "2.5px" }} htmlFor="">
                          Update all time slots
                        </label>
                        <input
                          style={{ marginBottom: "2.5px" }}
                          type="checkbox"
                          name=""
                          id=""

                          onChange={() =>
                            setBulValueAlterChecked(!bulValueAlterChecked)
                          }
                        />
                        <input
                          disabled={!bulValueAlterChecked}
                          type="text"
                          onBlur={(e) => handleBulkChangeBlur(e)}
                          value={bulkvalue}
                          onChange={(e) => handleBulkChange(e)}
                        />
                      </div>
                     {/* <ul
                        class="nav nav-pills d-flex justify-content-center align-items-center"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link active"
                            id="pills-home-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-time-0-6"
                            type="button"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                          >
                            00-06
                          </button>
                        </li>

                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link"
                            id="pills-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-time-6-12"
                            type="button"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="false"
                          >
                            06-12
                          </button>
                        </li>

                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link"
                            id="pills-contact-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-time-12-18"
                            type="button"
                            role="tab"
                            aria-controls="pills-contact"
                            aria-selected="false"
                          >
                            12-18
                          </button>
                        </li>

                        <li class="nav-item" role="presentation">
                          <button
                            class="nav-link"
                            id="pills-contact-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-time-18-0"
                            type="button"
                            role="tab"
                            aria-controls="pills-contact"
                            aria-selected="false"
                          >
                            18-00
                          </button>
                        </li>
                        </ul>*/} 
                      <div
                        class="tab-content time-opt m-auto"
                        id="pills-tabContent"
                      >
                        <div
                          class="tab-pane fade show active"
                          id="pills-time-0-6"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <div class="d-flex flex-wrap justify-content-between align-items-center">
                            <div class="time-fill-box d-flex flex-column align-items-center mb-3 mb-md-0">
                              <label for="" class="form-label">
                                00-01
                              </label>

                              <input
                                type="text"
                                class="form-control"
                                id="0"
                                boost_id={hourlydata[0]?.intID}
                                value={hourlydata[0]?.intBoost}
                                onBlur={handleBlur}
                                // onChange={(event) => setInputValue(event.target.value)}

                                onChange={(event) =>
                                  handleInputChange(event, 0)
                                }
                              />
                            </div>

                            <div class="time-fill-box d-flex flex-column align-items-center mb-3 mb-md-0">
                              <label for="" class="form-label">
                                01-02
                              </label>

                              <input
                                type="text"
                                class="form-control"
                                id="1"
                                onBlur={handleBlur}
                                boost_id={hourlydata[1]?.intID}
                                value={hourlydata[1]?.intBoost}
                                onChange={(event) =>
                                  handleInputChange(event, 1)
                                }
                              />
                            </div>

                            <div class="time-fill-box d-flex flex-column align-items-center mb-3 mb-md-0">
                              <label for="" class="form-label">
                                02-03
                              </label>

                              <input
                                type="text"
                                class="form-control"
                                id="2"
                                onBlur={handleBlur}
                                boost_id={hourlydata[2]?.intID}
                                value={hourlydata[2]?.intBoost}
                                onChange={(event) =>
                                  handleInputChange(event, 2)
                                }
                              />
                            </div>

                            <div class="time-fill-box d-flex flex-column align-items-center mb-3 mb-md-0">
                              <label for="" class="form-label">
                                03-04
                              </label>

                              <input
                                type="text"
                                class="form-control"
                                id="3"
                                onBlur={handleBlur}
                                boost_id={hourlydata[3]?.intID}
                                value={hourlydata[3]?.intBoost}
                                onChange={(event) =>
                                  handleInputChange(event, 3)
                                }
                              />
                            </div>

                            <div class="time-fill-box d-flex flex-column align-items-center mb-3 mb-md-0">
                              <label for="" class="form-label">
                                04-05
                              </label>

                              <input
                                type="text"
                                class="form-control"
                                id="4"
                                onBlur={handleBlur}
                                boost_id={hourlydata[4]?.intID}
                                value={hourlydata[4]?.intBoost}
                                onChange={(event) =>
                                  handleInputChange(event, 4)
                                }
                              />
                            </div>

                            <div class="time-fill-box d-flex flex-column align-items-center mb-3 mb-md-0">
                              <label for="" class="form-label">
                                05-06
                              </label>

                              <input
                                type="text"
                                class="form-control"
                                id="5"
                                onBlur={handleBlur}
                                boost_id={hourlydata[5]?.intID}
                                value={hourlydata[5]?.intBoost}
                                onChange={(event) =>
                                  handleInputChange(event, 5)
                                }
                              />
                            </div>
                          </div>
                        </div>
 
                        <div
                          class="tab-pane active"
                          id="pills-time-6-12"
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                        >
                          <div class="d-flex flex-wrap justify-content-between align-items-center">
                            <div class="time-fill-box d-flex flex-column align-items-center mb-3 mb-md-0">
                              <label for="" class="form-label">
                                06-07
                              </label>

                              <input
                                type="text"
                                class="form-control"
                                id="6"
                                onBlur={handleBlur}
                                boost_id={hourlydata[6]?.intID}
                                value={hourlydata[6]?.intBoost}
                                onChange={(event) =>
                                  handleInputChange(event, 6)
                                }
                              />
                            </div>

                            <div class="time-fill-box d-flex flex-column align-items-center mb-3 mb-md-0">
                              <label for="" class="form-label">
                                07-08
                              </label>

                              <input
                                type="text"
                                class="form-control"
                                id="7"
                                onBlur={handleBlur}
                                boost_id={hourlydata[7]?.intID}
                                value={hourlydata[7]?.intBoost}
                                onChange={(event) =>
                                  handleInputChange(event, 7)
                                }
                              />
                            </div>

                            <div class="time-fill-box d-flex flex-column align-items-center mb-3 mb-md-0">
                              <label for="" class="form-label">
                                08-09
                              </label>

                              <input
                                type="text"
                                class="form-control"
                                id="8"
                                onBlur={handleBlur}
                                boost_id={hourlydata[8]?.intID}
                                value={hourlydata[8]?.intBoost}
                                onChange={(event) =>
                                  handleInputChange(event, 8)
                                }
                              />
                            </div>

                            <div class="time-fill-box d-flex flex-column align-items-center mb-3 mb-md-0">
                              <label for="" class="form-label">
                                09-10
                              </label>

                              <input
                                type="text"
                                class="form-control"
                                id="9"
                                onBlur={handleBlur}
                                boost_id={hourlydata[9]?.intID}
                                value={hourlydata[9]?.intBoost}
                                onChange={(event) =>
                                  handleInputChange(event, 9)
                                }
                              />
                            </div>

                            <div class="time-fill-box d-flex flex-column align-items-center mb-3 mb-md-0">
                              <label for="" class="form-label">
                                10-11
                              </label>

                              <input
                                type="text"
                                class="form-control"
                                id="10"
                                onBlur={handleBlur}
                                boost_id={hourlydata[10]?.intID}
                                value={hourlydata[10]?.intBoost}
                                onChange={(event) =>
                                  handleInputChange(event, 10)
                                }
                              />
                            </div>

                            <div class="time-fill-box d-flex flex-column align-items-center mb-3 mb-md-0">
                              <label for="" class="form-label">
                                11-12
                              </label>

                              <input
                                type="text"
                                class="form-control"
                                id="11"
                                onBlur={handleBlur}
                                boost_id={hourlydata[11]?.intID}
                                value={hourlydata[11]?.intBoost}
                                onChange={(event) =>
                                  handleInputChange(event, 11)
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          class="tab-pane active"
                          id="pills-time-12-18"
                          role="tabpanel"
                          aria-labelledby="pills-contact-tab"
                        >
                          <div class="d-flex flex-wrap justify-content-between align-items-center">
                            <div class="time-fill-box d-flex flex-column align-items-center mb-3 mb-md-0">
                              <label for="" class="form-label">
                                12-13
                              </label>

                              <input
                                type="text"
                                class="form-control"
                                id="12"
                                onBlur={handleBlur}
                                boost_id={hourlydata[12]?.intID}
                                value={hourlydata[12]?.intBoost}
                                onChange={(event) =>
                                  handleInputChange(event, 12)
                                }
                              />
                            </div>

                            <div class="time-fill-box d-flex flex-column align-items-center mb-3 mb-md-0">
                              <label for="" class="form-label">
                                13-14
                              </label>

                              <input
                                type="text"
                                class="form-control"
                                id="13"
                                onBlur={handleBlur}
                                boost_id={hourlydata[13]?.intID}
                                value={hourlydata[13]?.intBoost}
                                onChange={(event) =>
                                  handleInputChange(event, 13)
                                }
                              />
                            </div>

                            <div class="time-fill-box d-flex flex-column align-items-center mb-3 mb-md-0">
                              <label for="" class="form-label">
                                14-15
                              </label>

                              <input
                                type="text"
                                class="form-control"
                                id="14"
                                onBlur={handleBlur}
                                boost_id={hourlydata[14]?.intID}
                                value={hourlydata[14]?.intBoost}
                                onChange={(event) =>
                                  handleInputChange(event, 14)
                                }
                              />
                            </div>

                            <div class="time-fill-box d-flex flex-column align-items-center mb-3 mb-md-0">
                              <label for="" class="form-label">
                                15-16
                              </label>

                              <input
                                type="text"
                                class="form-control"
                                id="15"
                                onBlur={handleBlur}
                                boost_id={hourlydata[15]?.intID}
                                value={hourlydata[15]?.intBoost}
                                onChange={(event) =>
                                  handleInputChange(event, 15)
                                }
                              />
                            </div>

                            <div class="time-fill-box d-flex flex-column align-items-center mb-3 mb-md-0">
                              <label for="" class="form-label">
                                16-17
                              </label>

                              <input
                                type="text"
                                class="form-control"
                                id="16"
                                onBlur={handleBlur}
                                boost_id={hourlydata[16]?.intID}
                                value={hourlydata[16]?.intBoost}
                                onChange={(event) =>
                                  handleInputChange(event, 16)
                                }
                              />
                            </div>

                            <div class="time-fill-box d-flex flex-column align-items-center mb-3 mb-md-0">
                              <label for="" class="form-label">
                                17-18
                              </label>

                              <input
                                type="text"
                                class="form-control"
                                id="17"
                                onBlur={handleBlur}
                                boost_id={hourlydata[17]?.intID}
                                value={hourlydata[17]?.intBoost}
                                onChange={(event) =>
                                  handleInputChange(event, 17)
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          class="tab-pane active"
                          id="pills-time-18-0"
                          role="tabpanel"
                          aria-labelledby="pills-contact-tab"
                        >
                          <div class="d-flex flex-wrap justify-content-between align-items-center">
                            <div class="time-fill-box d-flex flex-column align-items-center mb-3 mb-md-0">
                              <label for="" class="form-label">
                                18-19
                              </label>

                              <input
                                type="text"
                                class="form-control"
                                id="18"
                                onBlur={handleBlur}
                                boost_id={hourlydata[18]?.intID}
                                value={hourlydata[18]?.intBoost}
                                onChange={(event) =>
                                  handleInputChange(event, 18)
                                }
                              />
                            </div>

                            <div class="time-fill-box d-flex flex-column align-items-center mb-3 mb-md-0">
                              <label for="" class="form-label">
                                19-20
                              </label>

                              <input
                                type="text"
                                class="form-control"
                                id="19"
                                onBlur={handleBlur}
                                boost_id={hourlydata[19]?.intID}
                                value={hourlydata[19]?.intBoost}
                                onChange={(event) =>
                                  handleInputChange(event, 19)
                                }
                              />
                            </div>

                            <div class="time-fill-box d-flex flex-column align-items-center mb-3 mb-md-0">
                              <label for="" class="form-label">
                                20-21
                              </label>

                              <input
                                type="text"
                                class="form-control"
                                id="20"
                                onBlur={handleBlur}
                                boost_id={hourlydata[20]?.intID}
                                value={hourlydata[20]?.intBoost}
                                onChange={(event) =>
                                  handleInputChange(event, 20)
                                }
                              />
                            </div>

                            <div class="time-fill-box d-flex flex-column align-items-center mb-3 mb-md-0">
                              <label for="" class="form-label">
                                21-22
                              </label>

                              <input
                                type="text"
                                class="form-control"
                                id="21"
                                onBlur={handleBlur}
                                boost_id={hourlydata[21]?.intID}
                                value={hourlydata[21]?.intBoost}
                                onChange={(event) =>
                                  handleInputChange(event, 21)
                                }
                              />
                            </div>

                            <div class="time-fill-box d-flex flex-column align-items-center mb-3 mb-md-0">
                              <label for="" class="form-label">
                                22-23
                              </label>

                              <input
                                type="text"
                                class="form-control"
                                id="22"
                                onBlur={handleBlur}
                                boost_id={hourlydata[22]?.intID}
                                value={hourlydata[22]?.intBoost}
                                onChange={(event) =>
                                  handleInputChange(event, 22)
                                }
                              />
                            </div>

                            <div class="time-fill-box d-flex flex-column align-items-center mb-3 mb-md-0">
                              <label for="" class="form-label">
                                23-00
                              </label>

                              <input
                                type="text"
                                class="form-control"
                                id="23"
                                onBlur={handleBlur}
                                boost_id={hourlydata[23]?.intID}
                                value={hourlydata[23]?.intBoost}
                                value1="24"
                                onChange={(event) =>
                                  handleInputChange(event, 23)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="tab-pane platform fade"
                      id="pills-platform"
                      role="tabpanel"
                      aria-labelledby="pills-platform-tab"
                    >
                      <h2 class="mb-3 mb-md-4">Desktop</h2>

                      <div class="range-row d-flex align-items-center flex-wrap mb-4">
                        {platformdataD.map((item, index) => {
                          return (
                            <PlatFormBoostSlider
                              item={item}
                              platform={"desktop"}
                              index={index}
                              handleSliderChange={handleSliderChange}
                              sliderBlur={sliderBlur}
                              getSliderValue={getSliderValue}
                            />
                          );
                        })}
                      </div>

                      <h2 class="mb-3 mb-md-4">Mobile</h2>

                      <div class="range-row d-flex align-items-center flex-wrap">
                        {platformdataM.map((item, index) => {
                          return (
                            <PlatFormBoostSlider
                              item={item}
                              platform={"mobile"}
                              index={index}
                              handleSliderChange={handleSliderChange}
                              sliderBlur={sliderBlur}
                              getSliderValue={getSliderValue}
                            />
                          );
                        })}
                      </div>
                    </div>

                    <div
                      class="tab-pane platform result-others fade"
                      id="pills-others"
                      role="tabpanel"
                      aria-labelledby="pills-others-tab"
                    >
                      <div class="range-row d-flex align-items-center flex-wrap">
                        <div class="range-col">
                          <div class="range-desc d-flex justify-content-between align-items-center">
                            <span class="title">unknown:</span>

                            <span class="percentage">{unknownOsBoost} %</span>
                          </div>

                          <div class="range-slider">
                            <input
                              class="range"
                              type="range"
                              id="unknown-os"
                              value={unknownOsBoost}
                              onChange={(e) => handleSliderChange(e)}
                              onBlur={(e) => sliderBlur(e)}
                              min="0"
                              max="2000"
                            />
                          </div>
                        </div>

                        <div class="range-col">
                          <div class="range-desc d-flex justify-content-between align-items-center">
                            <span class="title">(No Consent):</span>

                            <span class="percentage">{noConsentOsBoost} %</span>
                          </div>

                          <div class="range-slider">
                            <input
                              class="range"
                              type="range"
                              step="1"
                              id="no-consent"
                              value={noConsentOsBoost}
                              onChange={(e) => handleSliderChange(e)}
                              onBlur={(e) => sliderBlur(e)}
                              min="0"
                              max="2000"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane platform result-others fade"
                      id="pills-location"
                      role="tabpanel"
                      aria-labelledby="pills-time-tab"
                    >
                      <div class="range-row d-flex align-items-center" style={{
                        width: "100%", display: "flex",
                        marginTop: "20px", justifyContent: "center"
                      }}>
                        <p >{"Abroad :"}</p>
                        <input
                          style={{ marginLeft: "10px", marginBottom: "14px" }}
                          type="text"
                          name=""
                          id=""
                          value={abroadBoost}
                          onBlur={() => updateLocationBoost()}
                          onChange={(event) => {
                            if (isNumeric(event?.target?.value)) {
                              setAbroadBoost(event.target?.value)
                            }
                          }}
                        />

                      </div>

                    </div>
                  </div>

                  <div class="d-flex justify-content-center align-items-center mt-5 mb-2">
                    <button
                      class="save-btn"
                      onClick={boostData}
                      value={buttonValue}
                    >
                      Save Changes
                      {(loading) && <ColorRing
                        colors={['#ffe9f0', '#f4e4a4', '#ffe9f0', '#f8e6ff', '#ffe9f0']}
                        height="25"
                        width="25"
                        color="#4fa94d"
                        ariaLabel="Signing in"
                        wrapperStyle={{}}
                        wrapperClass="wrapper-class"
                        visible={true} />}
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Placement;

function PlatFormBoostSlider({
  item,
  index,
  handleSliderChange,
  sliderBlur,
  platform,
}) {
  const maxValue = process.env.REACT_APP_MAX_SLIDER_VALUE
  return (
    <div class="range-col">
      <div class="range-desc d-flex justify-content-between align-items-center">
        <span class="title">{item?.name}</span>
        <span class="percentage">{item?.value}%</span>
      </div>
      <div class="range-slider">
        <input
          class="range"
          type="range"
          onChange={(e) => handleSliderChange(e, platform, index)}
          step="1"
          boost_id={item?.bid}
          onBlur={(e) => sliderBlur(e, platform)}
          name={item?.name}
          value={item?.value}
          id={item?.strValue}
          min="0"
          max={maxValue}
        />
      </div>

    </div>
  );
}
