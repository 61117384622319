import "./css/style.css";
import leftImage from "../Templates/images/login-left-img.svg";
import passEyeImage from "../Templates/images/pass-eye.svg";
import googleSignIn from "../Templates/images/google-logo.svg";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import normalLoginApi from "../../services/DataReader";
import { useState } from "react";
import getUrl from "../../helpers/GetUrl";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
const SignIn = (props) => {
  const nav = useNavigate();
  const backendurl = getUrl();
  const [loading, setloading] = useState(false);
  const [seepassword, setseepassword] = useState(false);
  const handleGoogleLoginSuccess = async (tokenResponse) => {
    const accessToken = tokenResponse.access_token;
    // console.log("accessToken==>", accessToken);

    // const url = process.env.REACT_APP_BASE_URL + '/user/token'
    const url = backendurl + "/api/user/management/google-sign-in";
    // console.log("url==>", url);

    const response = await axios
      .post(url, {
        googleAccessToken: accessToken,
      })
      .catch((err) => {
        //console.log(err.message)
      });
    // console.log("response==>", response?.data);
    
      if (response?.data?.success) {
        props.setname(response?.data?.username)
        if (response?.data?.newUser) {
        //  console.log("KMD about to expire ======>", response?.data)
          nav(`/update-kmd`,{state: {
            userID:response?.data?.userID
          }
        })
        return
        }
        if (response?.data?.isExpiryAwaiting) {
        //  console.log("KMD about to expire")
          // alert("KMD about to expire")
          confirmAlert({
            title: `Confirmation`,
            message: "KMD about to expire kindly renew",
            buttons: [
              {
                label: 'Yes',
                onClick: () => {
                  nav(`/update-kmd`,{state: {
                      userID:response?.data?.userID
                    }
                  })
                },
              },
              {
                label: 'No',
                onClick: () => {
                 
                },
              },
            ],
          });
        }
        nav("/");
      } else {
        alert(
          "Sign in failed : message".replace(
            "message",
            response?.data?.error || "unknown error"
          )
        );
      }
  
  };
  const login = useGoogleLogin({
    onSuccess: handleGoogleLoginSuccess,
    onError: (e) => {
    //  console.log("Login Failed", e);
    },
  });

  const normalLogin = async () => {
    const email = document.getElementById("email")?.value;
    const passwordhash = document.getElementById("password")?.value;
    const url = backendurl + "/api/user/management/sign-in";
    setloading(true);
    const response = await normalLoginApi(url, {
      email,
      passwordhash,
    });
   // console.log(response.isExpiryAwaiting)
    //  console.log("response")
    setloading(false);
    if (response.success) {
     
      props.setname(response.username)
      if (response?.publisherID) {
        nav(`/publisher/${response?.publisherName}/${response?.publisherID}`);
        return;
      }
      nav("/");
    } else {
      alert("login failed");
    }
  };
  return (
    <section class="login-container mt-4 mt-lg-0">
      <div class="container">
        <div class="content-box">
          <div class="row align-items-xl-center">
            <div class="col-lg-6">
              <div class="left-container">
                <img src={leftImage} alt="portfolio" />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="right-container mx-auto">
                <h1 class="mb-2">Login</h1>
                <span class="info-text">Log in to your account.</span>

                <div class="mb-4 mt-3 mt-md-5">
                  <label for="email" class="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="Enter your email"
                  />
                </div>
                <div class="mb-4 pass-sec">
                  <label for="password" class="form-label">
                    Password
                  </label>
                  <input
                    type={seepassword ? "text" : "password"}
                    class="form-control"
                    id="password"
                    placeholder="Enter your Password"
                  />
                  <div class="pass-view">
                    <img
                      src={passEyeImage}
                      onClick={() => setseepassword(!seepassword)}
                      alt=""
                    />
                  </div>
                </div>

                <div class="mt-3 password-suggest d-flex justify-content-between align-items-center">
                  <div class="form-check">

                    <label class="form-check-label" for="remember-me">

                    </label>
                  </div>
                  <div>
                    <a href="./forgot-password" class="d-block">
                      Forget Password ?
                    </a>
                  </div>
                </div>

                <div class="my-4">
                  <button class="btn btn-primary" onClick={() => normalLogin()}>
                    <span>Sign In</span>{" "}
                    {loading && (
                      <ColorRing
                        colors={[
                          "#ffe9f0",
                          "#f4e4a4",
                          "#ffe9f0",
                          "#f8e6ff",
                          "#ffe9f0",
                        ]}
                        height="25"
                        width="25"
                        color="#4fa94d"
                        ariaLabel="Signing in"
                        wrapperStyle={{}}
                        wrapperClass="wrapper-class"
                        visible={true}
                      />
                    )}
                  </button>
                </div>
                <div>
                  <button
                    class="btn btn-secondary d-flex align-items-center justify-content-center"
                    onClick={login}
                  >
                    {" "}
                    <img src={googleSignIn} alt="icon" class="me-3" />
                    Sign in with Google
                  </button>
                </div>

                <div class="signup-info mt-4 pt-3 d-flex align-items-center justify-content-center">
                  <span>Don’t have an account? contact us now!</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignIn;
