const getUrl = () => {
    let url = 'https://booster.twiago.com'
    if (window?.location?.hostname === 'localhost') {
        url = window?.location?.protocol+"//"+ window?.location?.hostname + ":20900"
    } else {
        url = window?.location?.protocol+"//"+ window?.location?.hostname
    }
    return url
}

export default getUrl;