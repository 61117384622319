import axios from "axios";
import DataObject from "../components/Dashboard/Datamanager";
import getUrl from "../helpers/GetUrl";
const addBoost = async (body, url, headers) => {
  try {
    const backendurl_bidding = getUrl()
    const jwtToken = localStorage.getItem("jwtToken");
    const response = await axios.post(
      `${backendurl_bidding}/api/bidding/setBoost`,
      body);
    // const response = await DataObject.Postboosts(body);
    if ((response.status === 201 || response.status === 200) && response?.data?.success ) {
      alert("Boost updated!")
      return {
        success: true,
      };
    } else {
      alert("Failed to update")
      return {
        success: false,
        message: response?.data?.message,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: error,
    };
  }
};

export default addBoost;
