import OtpLeft from "../Templates/images/otp-left.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import getUrl from "../../helpers/GetUrl";
const OtpEntryComp = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.getElementById("num1").focus();
  });
  const location = useLocation();
  const backendurl = getUrl()
  const email = location.state && location.state.email;
  const [otp, setOtp] = useState(["", "", "", ""]);
  const otpInputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const handleVerifyOTP = async () => {
    // Construct the API URL for OTP verification
    // const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/verify-otp`;
    const apiUrl = `${backendurl}/api/user/management/verify-otp`;

    // Prepare the data to send in the request
    const otpCode = otp.join("");
    const requestData = {
      email,
      otpCode,
    };

    try {
      // Send a POST request to your server to verify the OTP
      const response = await fetch(apiUrl, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const responseData = await response.json();
        const token = responseData.tokenString;
        window.alert("Otp verified successfully!");
        navigate("/new-password", {
          state: { token },
        });
      } else {
        window.alert("Otp verification failed!");
        console.error("OTP verification failed");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    // Ensure that the input value is a single digit
    if (/^[0-9]$/.test(value)) {
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = value;
        return newOtp;
      });
      // Automatically move to the next input field if not the last one
      if (index < otpInputRefs.length - 1) {
        const nextIndex = index + 1;
        // Move focus to the next input field with a slight delay
        setTimeout(() => {
          otpInputRefs[nextIndex].current.focus();
        }, 10);
      }
    } else if (value.length === 0) {
      // Handle backspace or clearing the input
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = value;
        return newOtp;
      });
      // Automatically move to the previous input field if not the first one
      if (index > 0) {
        otpInputRefs[index - 1].current.focus();
      }
    }
  };

  return (
    <section class="login-container mt-4 mt-lg-0">
      <div class="container">
        <div class="content-box">
          <div class="row align-items-xl-center">
            <div class="col-lg-6">
              <div class="left-container">
                <img src={OtpLeft} alt="image" />
              </div>
            </div>

            <div class="col-lg-6">
              <div class="right-container mx-auto">
                <h1 class="mb-2">Enter the OTP</h1>
                <div class="mb-2 mt-3 mt-md-5">
                  <label for="" class="form-label">
                    Enter the OTP
                  </label>
                </div>
                <div class="otp-container d-flex justify-content-between align-items-center mb-4">
                  <input
                    type="number"
                    class="form-control me-2 p-0 text-center"
                    id="num1"
                    ref={otpInputRefs[0]}
                    value={otp[0]}
                    onChange={(e) => handleOtpChange(e, 0)}
                  />
                  <input
                    type="number"
                    class="form-control me-2 p-0 text-center"
                    id="num2"
                    ref={otpInputRefs[1]}
                    value={otp[1]}
                    onChange={(e) => handleOtpChange(e, 1)}
                  />
                  <input
                    type="number"
                    class="form-control me-2 p-0 text-center"
                    id="num3"
                    ref={otpInputRefs[2]}
                    value={otp[2]}
                    onChange={(e) => handleOtpChange(e, 2)}
                  />
                  <input
                    type="number"
                    class="form-control p-0 text-center"
                    id="num4"
                    ref={otpInputRefs[3]}
                    value={otp[3]}
                    onChange={(e) => handleOtpChange(e, 3)}
                  />
                </div>

                <div>
                  <button
                    class="btn btn-primary"
                    onClick={handleVerifyOTP}
                  >
                    Submit One Time Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OtpEntryComp;
