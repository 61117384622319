// import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";


import "./PieChartComponent.css";

// const myDataOne = [
//         { title: "Netpoint media Gmbh", value: 4525, color: "#607cfc" },
//         { title: "Virtual Minds GmbH", value: 2200, color: "#58dca4" },
//         { title: "Netpoint media Gmbh", value: 1300, color: "#f05464" },
//         { title: "Virtual Minds GmbH", value: 4215, color: "#607cfc" },
//         { title: "Netpoint media Gmbh", value: 7425, color: "#58dca4" },
// ];


const properties = {
        width: "100%", height: "235px", backgroundColor: "white", padding: "10px"
}

const PieChartComponent = ({ value, auctions }) => {
        const COLORS = ["#8884d8", "#82ca9d", "#FFBB28", "#FF8042", "#AF19FF"];
       
        const CustomTooltip = ({ active, payload, label }) => {
                if (active) {
                  return (
                    <div
                      className="custom-tooltip"
                      style={{
                        backgroundColor: "#ffff",
                        padding: "5px",
                        border: "1px solid #cccc"
                      }}
                    >
                      <label>{`name: ${payload[0].name}`}</label>
                      <br />
                      <label>{`profit: ${Number(payload[0].value)?.toFixed(2)}`}</label>
                    </div>
                  );
                }
                return null;
              };
        if (auctions) {
                return (
                        <div id="container" style={properties} >
                                <ul id="color-list">
                                        {value?.map(function (item, index) {
                                                return (<div style={{ listStyle: "none", display: "flex", justifyContent: "space-between" }} key={index} >
                                                        <div style={{ backgroundColor: item.color, height: "14px", width: "14px", marginTop: "11px", borderRadius: "7px" }}></div>
                                                        <div style={{ height: "36px", width: "calc(100% - 18px)", listStyle: "blue", padding: "8px", display: "flex", justifyContent: "space-between" }}><span className="text-ssp-name" style={{ textAlign: "left" }}> {item.title} </span>  <span className="text-ssp-name" style={{ textAlign: "left" }}>  {(item?.winRate == "unde%") ? "0 %" : item?.winRate } </span></div>
                                                </div>)
                                        })}
                                </ul>
                        </div>
                );
        }

        return (
                <div id="container" style={properties} >
                        {!value?.length && <h3>None data available</h3>}
                        <PieChart width={250} height={250} >
                                <Pie
                                        data={value}
                                        color="#000000"
                                        dataKey="value"
                                        nameKey="title"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={100}
                                        fill="#8884d8"
                                >
                                        {value.map((entry, index) => (
                                                <Cell
                                                        key={`cell-${index}`}
                                                        fill={COLORS[index % COLORS.length]}
                                                />
                                        ))}
                                </Pie>
                                <Tooltip content={<CustomTooltip />} />
                                
                        </PieChart>
                        <ul id="color-list">
                                {value?.map(function (item, index) {
                                        const formattedValue = (
                                          item?.value || 0
                                        ).toFixed(2);
                                        return (
                                          <div
                                            style={{
                                              listStyle: "none",
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                            key={index}
                                          >
                                            <div
                                              style={{
                                                backgroundColor: item.color,
                                                height: "14px",
                                                width: "14px",
                                                marginTop: "11px",
                                                borderRadius: "7px",
                                              }}
                                            ></div>
                                            <div
                                              style={{
                                                height: "36px",
                                                width: "calc(100% - 18px)",
                                                listStyle: "blue",
                                                padding: "8px",
                                                display: "flex",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <span
                                                className="text-ssp-name"
                                                style={{ textAlign: "left" }}
                                              >
                                                {" "}
                                                {item.title}{" "}
                                              </span>{" "}
                                              <span
                                                className="text-ssp-name"
                                                style={{ textAlign: "left" }}
                                              >
                                                {" "}
                                                € {formattedValue}{" "}
                                              </span>
                                            </div>
                                          </div>
                                        );
                                })}
                        </ul>
                </div>
        );
};



export default PieChartComponent;
