export default function ({ value }) {
        if (value == 0 || String(value) === "NaN" || !value) {
                return <span style={{ color: "gray" }}>{"0.00"  + " Eur"}</span>  
        }
        const splites = String(value)?.split(".");
        let decimal = splites[1];
        const full_part = splites[0]
        if (!decimal) {
                decimal = "00"
        }
        if (decimal?.includes('E')) {
               decimal = decimal.replace("E","0")
        }
        decimal = decimal?.slice(0, 2)
        if (full_part.includes('-')) {
                return <span style={{ color: "red" }}>{full_part  + "." +  decimal   + " Eur"}</span>
        } else {
                return <span style={{ color: "green" }}>{full_part  + "." +  decimal  + " Eur"}</span>
        }
}