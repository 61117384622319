import ForgotLeftImage from "../Templates/images/forget-left.png";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import getUrl from "../../helpers/GetUrl"
import axios from "axios";
const ForgotPasswordComp = () => {
  const navigate = useNavigate();
  const domain = getUrl()
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const backendurl = domain

  const handleForgotPassword = async () => {
    try {
      setIsLoading(true);

      //   Make an API request to the "Forgot Password" endpoint
      const response = await axios.post(
        `${backendurl}/api/user/management/forgot-password`,
        {email: email }
      );
     // console.log("22",response?.data)
      if (response?.data?.success) {
        // If the request was successful, navigate to the OTP entry page
        navigate("/otp-entry", {
          state: {
            email,
          },
        });
      } else {
        window.alert("User not found!");
        // Handle errors, e.g., show an error message to the user
        console.error("Forgot Password request failed");
      }
    } catch (error) {
      console.error("Error sending forgot password request:", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <section class="login-container mt-4 mt-lg-0">
      <div class="container">
        <div class="content-box">
          <div class="row align-items-xl-center">
            <div class="col-lg-6">
              <div class="left-container">
                <img src={ForgotLeftImage} alt="image" />
              </div>
            </div>

            <div class="col-lg-6">
              <div class="right-container mx-auto">
                <h1 class="mb-2">Forgot Password</h1>

                <div class="mb-4 mt-3 mt-md-5">
                  <label for="email" class="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="Enter the registered email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <button
                    class="btn btn-primary"
                    onClick={handleForgotPassword}
                    disabled={isLoading}
                  >
                    {isLoading ? "Sending OTP..." : "Share OTP"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPasswordComp;
