import { Outlet, Link } from "react-router-dom";
import useScreenSize from "../customHooks/useScreenSize";
import { useEffect, useState } from "react";
import useAuthorize from "../customHooks/useAuthorize";
import SignIn from "./SignIn";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header/Header";
const Layout = ({name}) => {
    const showbar = useScreenSize()
    const signedIn = useAuthorize()
    const navigate = useNavigate();
    const [hideSidebar, setHideSidebar] = useState(false)
    const [isSignedIn, setIsSignedIn] = useState(false)
    useEffect(()=> {
        setHideSidebar(showbar)
    },[showbar])
    useEffect(()=>{
        setIsSignedIn(signedIn)
    },[signedIn])
    function clickedBtn(){
        setHideSidebar(!hideSidebar)
    }
    function updateSignin() {
        // console.log('inside update signed in')
        setIsSignedIn(true)
    }
    

    if (!isSignedIn && !signedIn){
        return <SignIn setIsSignedIn={setIsSignedIn}/>
    }

    const signOut = () => {
        
        navigate("/sign-in")
    }
    
    return (
        <div style={{ display: 'flex', flexDirection: 'column'}}>
            <Header name={name}/>
            <div style={{ display: 'flex', height: '100vh' }}>
                {/* {hideSidebar &&
                    <nav style={{ width: '200px', backgroundColor: "skyblue", height: '100%' }}>
                        <ul style={{ listStyleType: 'none', marginTop: '50px' }}>
                            <li style={{ margin: '10px' }}>
                                <Link to="/" style={{ textDecorationLine: 'none', fontSize: '19px', color: 'black', fontWeight: 600 }}>Home</Link>
                            </li>
                            <li style={{ margin: '10px' }} >
                                <Link to="/blogs" style={{ textDecorationLine: 'none', fontSize: '19px', color: 'black', fontWeight: 600 }} >Seconds</Link>
                            </li>
                            <li style={{ margin: '10px' }} >
                                <Link to="/blogs" style={{ textDecorationLine: 'none', fontSize: '19px', color: 'black', fontWeight: 600 }} >Third</Link>
                            </li>
                        </ul>
                    </nav>

                } */}
                <div style={{ width: '100%' }}>
                    <Outlet />
                </div>
            </div>
        </div>
    )
};

export default Layout;