import { all } from "axios";
import React from "react";
import {
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  LabelList,
  ComposedChart,
} from "recharts";

const CustomBarChart = (allData) => {
  const dotData = allData.logData;
  console.log("Boost",allData.boostData)

  // Get current date in "mm-dd-yyyy" format in Germany
  const options = { timeZone: 'Europe/Berlin', year: 'numeric', month: '2-digit', day: '2-digit' };

  let currentDateInGermany;

  switch (allData.currentDay) {
    case 'Today':
      const currentDate = new Date();
      currentDateInGermany = currentDate.toLocaleDateString('en-DE', options);
      break;
    case 'Yesterday':
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      currentDateInGermany = yesterday.toLocaleDateString('en-DE', options);
      break;
    case 'Day before yesterday':
      const dayBeforeYesterday = new Date();
      dayBeforeYesterday.setDate(dayBeforeYesterday.getDate() - 2);
      currentDateInGermany = dayBeforeYesterday.toLocaleDateString('en-DE', options);
      break;
    default:
      currentDateInGermany = currentDate.toLocaleDateString('en-DE', options);
      break;
  }

  let entriesWithCurrentDate = [];
  let latestThreeEntriesByHour = {};
  if(dotData){
  
  for (let hour = 0; hour <= 23; hour++) {
      const hourEntries = dotData[hour] || []; // If dotData[hour] is undefined, default to an empty array
      
      // Find the entry with the latest createdTime for this hour
      let latestEntryForHour = null;
      let latestCreatedTimeForHour = "00:00:00"; // Initialize to a minimum time value
  
      // Initialize an array to store the latest three entries for this hour
      let latestThreeEntriesForThisHour = [];
  
      hourEntries.forEach(entry => {
          const entryDate = entry.Date; // Assuming 'Date' is the key for the date field
          if (entryDate === currentDateInGermany) {
            console.log("entry fuound!!",entryDate)
              const createdTime = entry.createdTime; // Assuming 'createdTime' is the key for the time field
  
              // Track the latest three entries for this hour
              if (latestThreeEntriesForThisHour.length < 3) {
                  latestThreeEntriesForThisHour.push(entry);
              } else {
                  latestThreeEntriesForThisHour.sort((a, b) => b.createdTime.localeCompare(a.createdTime));
                  if (createdTime > latestThreeEntriesForThisHour[2].createdTime) {
                      latestThreeEntriesForThisHour.pop();
                      latestThreeEntriesForThisHour.push(entry);
                  }
              }
  
              // Update the latest entry for this hour
              if (createdTime > latestCreatedTimeForHour) {
                  latestCreatedTimeForHour = createdTime;
                  latestEntryForHour = entry;
              }
          }
      });
  
      // Push the latest entry for this hour to the result array
      if (latestEntryForHour) {
          entriesWithCurrentDate.push(latestEntryForHour);
      }
  
      // Store the latest three entries for this hour in the object
      if (latestThreeEntriesForThisHour.length > 0) {
          latestThreeEntriesByHour[hour] = latestThreeEntriesForThisHour;
      }
  }
}
console.log("today",entriesWithCurrentDate)
console.log("latest",latestThreeEntriesByHour)

  const customTooltipStyle = {
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    padding: "10px",
    borderRadius: "5px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
    // Add other CSS properties and values as needed
  };

  const allTimes = Array.from(
    { length: 24 },
    (_, index) => `${index.toString().padStart(2, "0")}:00`
  );

  let data = [];
  if (data.length == 0) {
    for (let i = 0; i < allTimes.length; i++) {
      let Boost = allData?.boostData[i] || 100
      if(allData.currentDate != "Today"){
          if(entriesWithCurrentDate?.some(obj => obj.Hour === allTimes[i])){
            Boost = entriesWithCurrentDate?.find(obj => obj.Hour === allTimes[i]).Boost
          }
      }
      if (allData.viewsSelected && allData.winRateSelected) {
       
        data.push({
          Hour: allTimes[i],
          Boost: Boost,
          Rohertrag: allData.lineData[i] ? parseFloat(allData.lineData[i]["Rohertrag"]).toFixed(2) : null,
          Views: allData.lineData[i] ?  parseFloat(allData.lineData[i]["Views"]).toFixed(2) : null,
          WinRate: allData.lineData[i] ? parseFloat(allData.lineData[i]["Win-Rate"]).toFixed(2) : null,
        });
      } else if (allData.viewsSelected) {
    
        data.push({
          Hour: allTimes[i],
          Boost: Boost,
          Rohertrag: allData.lineData[i] ?  parseFloat(allData.lineData[i]["Rohertrag"])?.toFixed(2) : null,
          Views: allData.lineData[i] ? parseFloat(allData.lineData[i]["Views"]).toFixed(2): null,
        });
      } else if (allData.winRateSelected) {
      
        data.push({
          Hour: allTimes[i],
          Boost: Boost,
          Rohertrag: allData.lineData[i] ?  parseFloat(allData.lineData[i]["Rohertrag"])?.toFixed(2) : null,
          WinRate: allData.lineData[i] ?  parseFloat(allData.lineData[i]["Win-Rate"]).toFixed(2) : null,
        });
      } else {
      
        data.push({
          Hour: allTimes[i],
          Boost: Boost,
          Rohertrag: allData.lineData[i] ?  parseFloat(allData.lineData[i]["Rohertrag"])?.toFixed(2) : null,
        });
      }
    }
  }

  let maxBoost = allData.boostData[0]
  let minBoost = allData.boostData[0]
  for(let i = 0; i < allData.boostData.length; i++){
    let boost = allData.boostData[i]
    if(boost>maxBoost) maxBoost = boost
    if(boost<minBoost) minBoost = boost
  } 
  const yAxisMaxBoost = Math.ceil(minBoost)

  //To get max and min profit
  let maxProfit = allData.lineData[0]["Rohertrag"];
  let minProfit = allData.lineData[0]["Rohertrag"];
  for (let i = 0; i < allData.lineData.length; i++) {
    let profit = parseInt(allData.lineData[i]["Rohertrag"], 10);
    if (profit > maxProfit) {
      maxProfit = profit;
    }
    if (profit < minProfit) {
      minProfit = profit;
    }
  }
  const buffer = 5;
  const yAxisMinProfit = Math.floor(minProfit) - buffer;
  const yAxisMaxProfit = Math.ceil(maxProfit) + buffer;

  //To get max and min views
  // if (allData.viewsSelected) {
  let maxViews = allData.lineData[0]["Views"];
  let minViews = allData.lineData[0]["Views"];
  for (let i = 0; i < allData.lineData.length; i++) {
    let views = parseInt(allData.lineData[i]["Views"], 10);
    if (views > maxViews) {
      maxViews = views;
    }
    if (views < minViews) {
      minViews = views;
    }
  }
  const yAxisMinViews = Math.floor(minViews) - buffer;
  const yAxisMaxViews = Math.ceil(maxViews) + buffer;
  // }

  //To get max and min winRate
  // if (allData.winRateSelected) {
  let maxWR = allData.lineData[0]["Win-Rate"];
  let minWR = allData.lineData[0]["Win-Rate"];
  for (let i = 0; i < allData.lineData.length; i++) {
    let wR = parseInt(allData.lineData[i]["Win-Rate"], 10);
    if (wR > maxWR) {
      maxWR = wR;
    }
    if (wR < minWR) {
      minWR = wR;
    }
  }
  const yAxisMinWR = Math.floor(minWR) - buffer;
  const yAxisMaxWR = Math.ceil(maxWR) + buffer;

  console.log("data",data)
 
  const CustomTooltipBar = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const lineeData = payload[0].payload;
      const hour = lineeData.Hour;
      const views = data.find((item) => item.Hour === hour).Views || null;
      const winRate = data.find((item) => item.Hour === hour).WinRate || null;
      const boost = data.find((item) => item.Hour === hour).Boost || null
      const hourWithoutLeadingZeroes = lineeData.Hour.split(':')[0].replace(/^0+(?=\d)/, '');

      return (
        <div style={customTooltipStyle}>
        <p>Hour: {lineeData.Hour}</p>
        {lineeData.Rohertrag && <p>Rohertrag: {lineeData.Rohertrag}</p>}
        {!latestThreeEntriesByHour[hourWithoutLeadingZeroes] && <p>Boost: {boost}</p>}
        {allData.viewsSelected && <p>Views: {views}</p>}
        {allData.winRateSelected && <p>Win-Rate: {winRate}</p>}
        {latestThreeEntriesByHour[hourWithoutLeadingZeroes] && latestThreeEntriesByHour[hourWithoutLeadingZeroes].length > 0 && (
            latestThreeEntriesByHour[hourWithoutLeadingZeroes].map((entry, index) => (
                <div key={index}>
                    <p>Boost: {entry.Boost ? entry.Boost : data[index].Boost}</p>
                    {entry.OldBoost !== undefined && <p>Boost (Old Value): {entry.OldBoost}</p>}
                    <p>Updated At: {entry.createdTime}</p>
                    {index !== latestThreeEntriesByHour[hourWithoutLeadingZeroes].length - 1 && <hr style={{ borderColor: 'grey', margin: '10px 0' }} />} {/* Add grey line if not the last entry */}
                </div>
            ))
        )}
    </div>
      );
    }
    return null;
  };

  return (
    <ComposedChart width={window.innerWidth * 0.65} height={400} data={data}>
      <CartesianGrid stroke="transparent" />
      <Tooltip content={<CustomTooltipBar />} />
      <Legend />
      <XAxis dataKey={"Hour"} />
      <YAxis yAxisId="left" orientation="left"
      domain={[0,yAxisMaxBoost]}/>
      <YAxis
        yAxisId="right"
        orientation="right"
        domain={[yAxisMinProfit, yAxisMaxProfit]}
      />
      {allData.viewsSelected ? (
        <YAxis
          yAxisId="views"
          orientation="left"
          domain={[yAxisMinViews, yAxisMaxViews]}
        />
      ) : null}
      {allData.winRateSelected ? (
        <YAxis
          yAxisId="winRate"
          orientation="left"
          domain={[yAxisMinWR, yAxisMaxWR]}
        />
      ) : null}

      {allData.boostSelected?( <Bar
        dataKey="Boost"
        yAxisId="left"
        fill="#3182bd"
        barSize={15}
        radius={[10, 10, 0, 0]}
        isAnimationActive={true}
        shape={(props) => {
          const { x, y, width, height } = props;
          const data = props.payload;
          const dotDataItem = entriesWithCurrentDate?.find((item) => item.Hour === data.Hour && !isNaN(item.OldBoost));

          return (
            <g>
              <rect x={x} y={y} width={width} height={height} fill="#3182bd" />
              {dotDataItem && (
                <circle
                  cx={x + width / 2}
                  cy={y + 10}
                  r={3}
                  fill="black"
                  data-value={dotDataItem.DotValue}
                />
              )}
            </g>
          );
        }}
      >
        <LabelList dataKey="Boost" position="top" fontSize={8} />
      </Bar>):null}
      {allData.rohertragSelected ? (
        <Line
          dataKey="Rohertrag"
          stroke="#ab1f34"
          yAxisId="right"
          strokeWidth={2}
          connectNulls={true}
        />
      ) : null}
      {allData.viewsSelected ? (
        <Line
          dataKey="Views"
          stroke="#00ff00"
          yAxisId="views"
          strokeWidth={2}
          connectNulls={true}
        />
      ) : null}
      {allData.winRateSelected ? (
        <Line
          dataKey="WinRate"
          // stroke="#00ff00"
          stroke="#FFA500"
          yAxisId="winRate"
          strokeWidth={2}
          connectNulls={true}
        />
      ) : null}

      <ReferenceLine
        y={0}
        yAxisId="right"
        stroke="#000000"
        strokeWidth={1}
        isFront={false}
        label={{ value: "0", position: "right" }}
      />
    </ComposedChart>
  );
};

export default CustomBarChart;
