import { useState, useEffect } from "react";

const useScreenSize = (btnClicked) => {
  const [show, setShow] = useState(true);

  function adjustWidth () {
    const width = window.innerWidth;
    if (width < 980) {
        setShow(false)
    } else {
        setShow(true)
    }
  }
  
  useEffect(function () {
   window.addEventListener('resize',adjustWidth) 
   adjustWidth()
  }, []);

  return show;
};

export default useScreenSize;